<template>
  <div class="editorUIStyle">
    <label v-html="title" class="form-label" for="inputImage"></label><span v-tooltip="dictionary.hiddenIfEmpty" class="badge rounded-pill bg-info text-white ms-2 small"><i class="fas fa-eye-slash small"></i></span>
    <div class="input-group">
      <input v-model="imageLink" id="inputImage" type="text" class="form-control" placeholder="https://...">
      <button @click.prevent="uploadImage" class="btn btn-success btn-raised text-light"><i class="fas fa-upload"></i></button>
      <button @click.prevent="$refs.mediaLibraryModalInput.open()" class="btn btn-primary btn-raised text-light"><i class="fas fa-box"></i></button>
    </div>
    <ImageLibraryModal @import="imageLink = $event.link || imageLink" :ref="`mediaLibraryModalInput`" :title="`<i class='fas fa-book-open me-3'></i>${dictionary.leftBar.mediaLibraryLabel}`" :enable-select-button="true"/>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import VueNotifications from 'vue-notifications';
import ImageLibraryModal from '../Modals/ImageLibraryModal.vue';
import firebaseReference from '../../store/FirebaseReference';

export default {
  name: 'InputImageUpload',
  components: { ImageLibraryModal },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['dictionary']),
    imageLink: {
      get()
      {
        return this.value;
      },
      set(newLink)
      {
        this.$emit('change', newLink);
      },
    },
  },
  methods: {
    uploadImage()
    {
      window.cptgUploader.init('identifiantUnique', `${firebaseReference.eventID}/badge/img/`, {
        apiKey: 'iFxsKguXQdlc7SbA6Fn3',
        sources: ['local'],
        lang: this.language || 'fr',
        theme: 'light',
        useEditor: false,
        forceSquareCrop: false,
        maxFileSize: 20,
      },
      (result) => {
        this.$store.dispatch('addToMediaList', {
          deleteURL: result.deleteURL || '',
          fileID: result.fileID || '',
          link: result.originalURL || '',
          thumbnail: result.thumbURL || '',
          title: `media-${this.$chance.string(this.$newIDParams)}`,
        }).then((media) => {
          this.uploadSuccess();
          this.$emit('change', `${media.link}?force_format=png`);
        });
      }).open();
    },
  },
  notifications: {
    uploadSuccess: {
      type: VueNotifications.types.success,
      title: 'Image uploadé !',
      message: 'Image uploadé avec succès.',
    },
    uploadError: {
      type: VueNotifications.types.error,
      title: 'Erreur',
      message: 'Une erreur est survenue lors de l\'envois de l\'image.',
    },
  },
};

</script>

<style scoped>

</style>
