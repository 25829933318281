<template>
  <div class="row h-100">
    <div v-if="!loaded" class="col-12 text-center mt-5">
      <h5 class="text-muted">{{ dictionary.deploymentModal.historyTabLoading }}</h5>
    </div>
    <div class="col-12 h-auto" v-if="loaded && liveCommitInfo">
      <card>
        <template v-slot:header-left><h5 class="text-white mb-0">{{ dictionary.deploymentModal.historyTabProductionVersion }}</h5></template>
        <table class="table">
          <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col">{{ dictionary.author }}</th>
            <th scope="col">{{ dictionary.date }}</th>
            <th scope="col">{{ dictionary.message }}</th>
            <th scope="col"></th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td><i v-tooltip.top="getTooltipCommitID(liveCommitInfo.id)" class="ms-3 fas fa-tags text-secondary"></i></td>
            <td>{{liveCommitInfo.author || dictionary.deploymentModal.unknown}}</td>
            <td>{{ formatDate(liveCommitInfo.date || '') }}</td>
            <td>{{liveCommitInfo.message || ''}}</td>
            <td>
              <span class="badge rounded-pill bg-info">{{ dictionary.production }}</span>
            </td>
            <td class="d-flex justify-content-end h-100">
              <button v-tooltip.top="`${dictionary.deploymentModal.downloadTooltip}`" v-on:click.prevent="downloadCommit(liveCommitInfo)" class="btn btn-success btn-raised mx-1"><i class="me-0 fas fa-download"></i></button>
            </td>
          </tr>
          </tbody>
        </table>
      </card>
    </div>
    <div v-if="loaded && websiteHistory.length >= 1" class="col-12" style="height: calc(100% - 200px)">
      <card class="h-100">
        <template v-slot:header-left><h5 class="text-white mb-0">{{ dictionary.deploymentModal.deploymentHistoryTitle }}<span class="ms-3 badge rounded-pill bg-info">{{ websiteHistory.length }}</span></h5></template>
        <perfect-scrollbar class="h-100 pe-3" :options="{ wheelPropagation: false }">
          <table class="table">
            <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">{{ dictionary.author }}</th>
              <th scope="col">{{ dictionary.date }}</th>
              <th scope="col">{{ dictionary.message }}</th>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(commit, key) in websiteHistory" :key="key">
              <td><i v-tooltip.top="getTooltipCommitID(commit.id)" class="ms-3 fas fa-tags text-secondary"></i></td>
              <td>{{commit.author || dictionary.deploymentModal.unknown}}</td>
              <td>{{ formatDate(commit.date || '') }}</td>
              <td v-if="commit.message !== ''">{{commit.message || ''}}</td>
              <td v-else class="text-muted small">{{ dictionary.deploymentModal.noMessage }}</td>
              <td><span v-if="liveCommit.commitId === commit.id" class="badge rounded-pill bg-info">{{ dictionary.production }}</span></td>
              <td class="d-flex justify-content-end h-100">
                <button v-tooltip.top="`${dictionary.deploymentModal.rollbackTooltip}`" v-on:click.prevent="rollbackCommit(commit)" class="btn btn-primary btn-raised mx-1"><i class="fas fa-undo-alt"></i></button>
                <button v-tooltip.top="`${dictionary.deploymentModal.downloadTooltip}`" v-on:click.prevent="downloadCommit(commit)" class="btn btn-success btn-raised mx-1"><i class="me-0 fas fa-download"></i></button>
              </td>
            </tr>
            </tbody>
          </table>
        </perfect-scrollbar>
      </card>
    </div>
    <div v-else-if="loaded" class="row">
      <div class="col-auto mx-auto mt-4">
        <p class="text-muted">{{ dictionary.deploymentModal.noDeploymentHistory }}</p>
      </div>
    </div>
  </div>
</template>

<script>

import VueNotifications from 'vue-notifications';
import { mapGetters } from 'vuex';
import Card from '../../Utilities/Card.vue';

export default {
  name: 'DeploymentHistory',
  components: { Card },
  data()
  {
    return {
      loaded: false,
      history: {},
    };
  },
  mounted()
  {
    this.$store.dispatch('getBadgeHistory').then((val) => {
      this.loaded = true;
      this.history = val;
    });
  },
  computed: {
    ...mapGetters(['liveCommit', 'dictionary']),
    websiteHistory()
    {
      return Object.values(this.history || {}).reverse();
    },
    liveCommitInfo()
    {
      let liveCommitData = {};
      this.websiteHistory.forEach((commit) => {
        if (commit.id === this.liveCommit.commitId)
          liveCommitData = { ...commit };
      });
      return liveCommitData;
    },
  },
  methods: {
    getTooltipCommitID(commitID)
    {
      return `Identifiant : ${commitID}`;
    },
    formatDate(timestamp)
    {
      return this.$formatDate(new Date(timestamp));
    },
    rollbackCommit(commit)
    {
      // TODO verifier ca
      this.$store.dispatch('setLiveBadge', { ...commit }).then(() => {
        if (commit.badgeContent)
        {
          if (commit.badgeContent.body)
            this.$store.commit('setBodyConfig', { config: commit.badgeContent.body, dbSync: false });
          if (commit.badgeContent.origin)
            this.$store.commit('setBadgeContentOrigin', { origin: commit.badgeContent.origin, dbSync: false });
          this.rollbackNotification();
        }
      });
    },
    downloadCommit(commit)
    {
      const out = { ...commit.badgeContent };
      const jsonData = JSON.stringify(out || {}, null, 4);
      const blob = new Blob([jsonData], { type: 'text/plain' });
      const e = document.createEvent('MouseEvents');
      const a = document.createElement('a');
      a.download = `deployment_${commit.id}.json`;
      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
      e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      a.dispatchEvent(e);
    },
  },
  notifications: {
    rollbackNotification: {
      type: VueNotifications.types.success,
      title: 'Rollback effectué !',
      message: 'Le site en production a été rollback vers un precedent deployement.',
    },
  },
};
</script>
<style scoped>
</style>
