<template>
  <form @submit.stop.prevent>
    <slot name="form-start"></slot>
    <div v-for="(prop, propIdx) in this.properties" :key="`property${propIdx}`" class="mb-2">
      <InputColorPicker v-if="prop === 'backgroundColor'" :label="dictionary.styleEditor.backgroundColor" :value="propertyGetValue('backgroundColor')" @change="propertySetValue('backgroundColor', $event)"/>
      <InputColorPicker
          v-else-if="prop === 'color'"
          :label="dictionary.styleEditor.fontColor"
          :value="propertyGetValue('color')"
          @change="propertySetValue('color', $event)"/>
      <InputImageUpload
          v-else-if="prop === 'backgroundImage'"
          v-bind:title="dictionary.styleEditor.backgroundImage"
          v-bind:value="propertyGetValue('backgroundImage')"
          @change="propertySetValue('backgroundImage', $event)"/>
      <hr v-else-if="prop === 'hr'">
      <AlignmentProperties v-else-if="prop === 'alignment'" :value="propertyGetValue('alignment')" @change="propertySetValue('alignment', $event)"/>
      <div v-else-if="prop === 'fontFamily' && properties.includes('fontWeight')" class="row">
        <div class="col">
          <FontSelector
              :label="dictionary.font"
              :imported-fonts="googleFonts"
              :value="propertyGetValue('fontFamily')"
              @change="propertySetValue('fontFamily', $event)"/>
        </div>
        <div class="col-auto">
          <FontWeightSelector
              :label="dictionary.styleEditor.fontWeight"
              :value="propertyGetValue('fontWeight')"
              @change="propertySetValue('fontWeight', $event)"/>
        </div>
      </div>
      <FontSelector
          v-else-if="prop === 'fontFamily'"
          :label="dictionary.font"
          :imported-fonts="googleFonts"
          :value="propertyGetValue('fontFamily')"
          @change="propertySetValue('fontFamily', $event)"/>
      <FontSizeInput
          v-else-if="prop === 'fontSize'"
          :label="dictionary.styleEditor.fontSize"
          :value="propertyGetValue('fontSize')"
          @change="propertySetValue('fontSize', $event)"/>
    </div>
    <slot name="form-end"></slot>
    <div v-if="!noButtons" class="row justify-content-end my-2 mb-0">
      <div class="col-auto">
        <div class="dropdown">
          <button class="btn btn-sm btn-secondary btn-raised dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"><i :class="`fa-cog`" class="fa pull-right"></i></button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li><a @click.prevent="exportStyle" class="dropdown-item"><i class="fas fa-download me-3"></i>{{ dictionary.styleEditor.exportStyle }}</a></li>
            <li><a @click.prevent="importButtonClick" class="dropdown-item"><i class="fas fa-upload me-3"></i>{{ dictionary.styleEditor.importStyle }}</a></li>
            <li><a @click.prevent="deleteStyle" class="dropdown-item text-danger"><i class="fas fa-trash me-3"></i>{{ dictionary.styleEditor.deleteStyle }}</a></li>
          </ul>
        </div>
      </div>
      <div class="col-12">
        <input @change="importStyle" accept="application/JSON" ref="importHiddenInput" type="file" style="visibility: hidden">
      </div>
    </div>
  </form>
</template>

<script>

import { mapGetters } from 'vuex';
import InputColorPicker from './InputColorPicker.vue';
import FontSelector from './FontSelector.vue';
import FontSizeInput from './FontSizeInput.vue';
import FontWeightSelector from './FontWeightSelector.vue';
import InputImageUpload from './InputImageUpload.vue';
import AlignmentProperties from './AlignmentProperties.vue';

export default {
  name: 'StyleEditor',
  components: {
    AlignmentProperties,
    InputImageUpload,
    FontWeightSelector,
    FontSizeInput,
    FontSelector,
    InputColorPicker,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    noButtons: {
      type: Boolean,
      required: false,
      default: false,
    },
    properties: {
      type: Array,
      required: false,
      default: () => [
        'fontFamily',
        'fontWeight',
        'fontSize',
        'color',
        'backgroundColor',
        'hr',
      ],
      validator: (props) => {
        let valid = true;
        (props || []).forEach((prop) => {
          if (!['fontFamily', 'fontWeight', 'fontSize', 'color', 'backgroundColor', 'hr', 'backgroundImage', 'alignment'].includes(prop))
            valid = false;
        });
        return valid;
      },
    },
    enableCustomProperties: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    ...mapGetters(['dictionary', 'styleDefaultValues', 'googleFonts', 'styleDefaultValues']),
  },
  methods: {
    deleteStyle()
    {
      this.$emit('change', this.styleDefaultValues);
    },
    importButtonClick() {
      this.$refs.importHiddenInput.click();
    },
    importStyle(ev)
    {
      const file = ev.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        try {
          const object = JSON.parse(e.target.result);
          if (object !== undefined)
            this.$emit('change', { ...this.value, ...object });
        } catch (ex)
        {
          console.log(`Invalid config file : ${ex.message}`);
        }
      };
      reader.readAsText(file);
    },
    exportStyle()
    {
      const jsonData = JSON.stringify(this.value || {}, null, 4);
      const blob = new Blob([jsonData], { type: 'text/plain' });
      const e = document.createEvent('MouseEvents');
      const a = document.createElement('a');
      a.download = `style_${this.$chance.string({
        length: 5,
        symbols: false,
        casing: 'lower',
        numeric: true,
        alpha: true,
      })}.json`;
      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
      e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      a.dispatchEvent(e);
    },
    propertyGetValue(propertyName)
    {
      return this.value[propertyName] || this.styleDefaultValues[propertyName];
    },
    propertySetValue(propertyName, value)
    {
      const out = { ...this.value };
      out[propertyName] = value;
      this.$emit('change', out);
    },
  },
};

</script>

<style scoped>

</style>
