<template>
  <div class="h-100" v-on:scroll.stop>
    <ErrorPage v-if="isLoadingError" />
    <LoadingPage v-else-if="isLoading"/>
    <MobileErrorPage v-else-if="isMobile && !forceMobileAccess"/>
    <div v-else-if="isBadgeContent" class="vh-100 position-relative container-fluid">
      <div class="row h-100">
        <div class="col-auto h-100 px-0">
          <left-bar class="h-100" @update="$refs.badgePreview.reload();"/>
        </div>
        <perfect-scrollbar class="col px-0 h-100 position-relative" v-bind:options="{ wheelPropagation: false }" @click="$refs.badgePreview.itemClick(-1)">
          <BadgePreview ref="badgePreview" style="padding-top: 60px; padding-bottom: 50px;"/>
          <SynchroWidget class="position-absolute" style="left: 20px; bottom: 10px;"/>
        </perfect-scrollbar>
      </div>
      <Topbar class="position-absolute" style="right: 22px; top: 0;"/>
      <BottomWidget class="position-absolute" style="right: 22px; bottom: 0;"/>
      <NewVersionAlert @reload="$refs.badgePreview.reload(); pendingNewVersion = false" @dismiss="pendingNewVersion = false" v-if="pendingNewVersion" class="position-absolute"/>
    </div>
    <div v-else class="h-100 position-relative container-fluid p-0">
      <NewBadgeWizzard/>
    </div>
  </div>
</template>

<script>

import VueNotifications from 'vue-notifications';
import { mapGetters } from 'vuex';
import BadgePreview from '@/components/BadgePreview.vue';
import NewVersionAlert from '@/components/NewVersionAlert.vue';
import Topbar from './components/TopBar/Topbar.vue';
import LeftBar from './components/LeftBar/LeftBar.vue';
import LoadingPage from './components/LoadingPage.vue';
import ErrorPage from './components/ErrorPage.vue';
import SynchroWidget from './components/Utilities/SynchroWidget.vue';
import NewBadgeWizzard from './components/NewBadgeWizzard.vue';
import MobileErrorPage from './components/MobileErrorPage.vue';
import BottomWidget from './components/BottomWidget.vue';

export default {
  name: 'App',
  components: {
    BottomWidget,
    NewVersionAlert,
    BadgePreview,
    MobileErrorPage,
    NewBadgeWizzard,
    SynchroWidget,
    ErrorPage,
    LeftBar,
    Topbar,
    LoadingPage,
  },
  mounted()
  {
    this.$store.dispatch('loadTokenParams');
    window.addEventListener('mousemove', (e) => {
      if (e.ctrlKey && !this.keepRatio)
        this.$store.commit('setKeepRatio', { value: true });
      else if (!e.ctrlKey)
        this.$store.commit('setKeepRatio', { value: false });
    });
    window.addEventListener('keydown', (e) => {
      if (e.key === 'Control')
        this.$store.commit('setKeepRatio', { value: true });
    });
    window.addEventListener('keyup', (e) => {
      if (e.key === 'Control')
        this.$store.commit('setKeepRatio', { value: false });
    });
  },
  created()
  {
    this.$store.watch((state) => state.zoomLevel, () => {
      this.$refs.badgePreview.reload();
    });
    this.$store.watch((state) => state.liveCommit, (newCommit, oldCommit) => {
      if (oldCommit.commitId !== undefined && oldCommit.commitId !== newCommit.commitId && newCommit.commitId !== this.$store.state.lastDeploymentID)
      {
        this.pendingNewVersion = true;
        this.newCommitNotif({
          message: `Une nouvelle version viens d'être deployé en production<br>ID: ${newCommit.commitId}`,
        });
      }
    });
  },
  data()
  {
    return {
      pendingNewVersion: false,
    };
  },
  computed: {
    ...mapGetters([
      'isLoading',
      'isLoadingError',
      'dictionary',
      'googleFonts',
      'isBadgeContent',
      'styleDefaultValues',
      'isMobile',
      'forceMobileAccess',
      'zoomLevel',
      'keepRatio',
    ]),
  },
  notifications: {
    newCommitNotif: {
      type: VueNotifications.types.warn,
      title: 'Nouvelle version deployé !',
      message: 'Une nouvelle version viens d\'être deployé en production.',
    },
  },
};

</script>

<style>

.central_layout {
  padding-top: 100px;
}

</style>
