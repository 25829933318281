import { firebaseAction } from 'vuexfire';
import firebaseReference from './FirebaseReference';

/**
 * Firebase -> store bindings
 * it's defined through vuexfire and called by the bindToFirebase action
 */
export default {
  /**
   * Bind the website content to state.coworkers
   */
  bindCoworkers: firebaseAction(async function callback({ bindFirebaseRef })
  {
    console.log('Binding coworkers');
    const fbRefRoot = await firebaseReference.root;
    return bindFirebaseRef('coworkers', fbRefRoot.child('coworkers'));
  }),

  /**
   * Bind the website content to state.liveCommit
   */
  bindLiveCommit: firebaseAction(async function callback({ bindFirebaseRef })
  {
    console.log('Binding live commit');
    const fbRefRoot = await firebaseReference.root;
    return bindFirebaseRef('liveCommit', fbRefRoot.child('live'));
  }),

  /**
   * Bind the website content to state.badgeContent
   */
  bindEventData: firebaseAction(async function callback({ bindFirebaseRef })
  {
    console.log('Binding event data');
    const fbRefRoot = await firebaseReference.ref;
    return bindFirebaseRef('eventData', fbRefRoot.ref('datas').child(firebaseReference.eventID));
  }),
};
