<template>
  <div :id="id" class="w-100 h-100 overflow-hidden" :style="computedStyle">
    <img :src="imgSource" :data-cloudinary-parameters="cloudinaryParameters" class="img-fluid w-100" alt="">
  </div>
</template>

<script>

export default {
  name: 'ImageComponent',
  props: {
    id: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    cloudinaryParameters()
    {
      return this.item.cloudinaryParameters || undefined;
    },
    imgSource()
    {
      return this.item.backgroundImage || undefined;
    },
    computedStyle()
    {
      const out = {};
      const backgroundColor = this.item.backgroundColor || undefined;
      if (backgroundColor)
      {
        if (backgroundColor.hex8 !== undefined && backgroundColor.hex8 !== '')
          out.backgroundColor = backgroundColor.hex8;
        else if (backgroundColor.rgba !== undefined)
          out.backgroundColor = `rgba(
            ${backgroundColor.rgba.r},
            ${backgroundColor.rgba.g},
            ${backgroundColor.rgba.b},
            ${backgroundColor.rgba.a}
          )`;
      }
      return out;
    },
  },
};

</script>

<style scoped>

</style>
