import { version } from '../../../package.json';
import { fbInit } from '../FirebaseReference';
import FirebaseBindings from '../FirebaseBindings';
import coWorkerActions from './coWorkerActions';
import databaseAccessorActions from './databaseAccessorActions';
import deploymentActions from './deploymentActions';
import mediasActions from './mediasActions';
import styleActions from './styleActions';

const actions = {
  ...FirebaseBindings,
  ...coWorkerActions,
  ...databaseAccessorActions,
  ...deploymentActions,
  ...mediasActions,
  ...styleActions,

  async loadTokenParams({ commit, dispatch })
  {
    const searchParams = new URLSearchParams(window.location.search);
    const claims = await fbInit(searchParams.get('tk') || '');
    if (!claims)
    {
      console.log('Error : missing parameter tokenID');
      commit('toggleLoadingError', { error: true, content: { code: 403, message: '' } });
      return;
    }
    commit('setVersionNumber', { version, dbSync: false });
    const lang = claims.lang;
    commit('setLang', { lang: lang || 'fr', dbSync: false });

    const name = claims.name;
    if (name)
      commit('setWorkerName', { name, dbSync: false });

    dispatch('getBadgeLiveContent').then((value) => {
      commit('initWorkerData');
      commit('setBadgeContent', { content: value });
      dispatch('bindEventData');
      dispatch('bindLiveCommit');
      dispatch('bindCoworkers').then(() => {
        setInterval(() => dispatch('removeOldWorker'), 6000);
        setInterval(() => dispatch('setWorkerData'), 3000);
      });
      commit('toggleLoading', { value: false, dbSync: false });
    });
  },
};

export default actions;
