import { uuid } from 'vue-uuid';
import firebaseReference from '../FirebaseReference';

export default {
  /**
   * Get and return all the website medias added through the builder
   * @warning Theses medias are different from event's document
   * @param getters
   * @returns An array containing objects representing medias
   */
  async getWebsiteMedias({ getters })
  {
    let out = {};
    const fbRefRoot = await firebaseReference.root;
    await fbRefRoot.child('medias').once('value', (snapshot) => { out = snapshot.val(); });
    return out;
  },

  /**
   * Remove a specific media from the list in base
   * @param getters
   * @param payload the media you want to remove, you must specify the id field
   * @returns true on success
   */
  async removeFromMediaList({ getters }, payload)
  {
    console.log('remove media from list', payload.id);
    const fbRefRoot = await firebaseReference.root;
    return fbRefRoot.child('medias').child(payload.id).remove().then(() => true);
  },

  /**
   * Add a document to the list in base
   * @param getters
   * @param payload the media you want to add, you must specify the id field
   * @returns true on success
   */
  async addToMediaList({ getters }, payload)
  {
    const media = {
      ...payload,
      date: Date.now(),
      id: uuid.v4(),
      uploadedBy: getters.workerData.name,
    };
    console.log('Add Media to list', payload.link);
    const fbRefRoot = await firebaseReference.root;
    return fbRefRoot.child('medias').child(media.id).set(media).then(() => media);
  },

  /**
   * Upload a font file to cloudinary and return he's access url
   * @param payload = {
   *   fileData: the font file actual datas
   *   filename: the file name that will be used as public_id
   * }
   * @returns the file access URL
   */
  async uploadFont(_, { fileData, fileName })
  {
    const formData = new FormData();
    formData.append('', fileData, fileName);
    formData.append('apiKey', 'iFxsKguXQdlc7SbA6Fn3');
    formData.append('folder', `${firebaseReference.eventID}/badge/`);

    const response = await fetch('https://europe-west1-captag-events.cloudfunctions.net/uploads/uploadFile', { method: 'POST', body: formData });
    const responseBody = await response.json();
    if (!response.ok || !responseBody || !responseBody.url)
    {
      console.log(`Error while uploading font : ${response.message}`);
      return undefined;
    }
    return responseBody.url || '';
  },
};
