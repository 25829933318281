<template>
  <div :style="computedStyle" class="h-100 w-100">
    <p v-html="text"
        :id="id"
        :class="computedClass"
        :data-shrinkCharQuantity="shrinkCharQuantity"
        :data-shrinkFactor="shrinkFactor"
        :data-style-shrink="(item.textBehavior || 'wrap') === 'decrease' ? preComputedShrinkFactorExpression : ''"
        :style="(item.textBehavior || 'wrap') === 'decrease' && !deploymentMode ? computedShrinkFactorExpression : 'overflow-wrap: anywhere;'"
        style="line-height: 1.1;"
        class="p-0 m-0"></p>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'TextComponent',
  props: {
    id: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    deploymentMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['eventData']),
    shrinkFactor()
    {
      return this.item.shrinkFactor || 0;
    },
    shrinkCharQuantity()
    {
      return this.item.shrinkCharQuantity || 10;
    },
    text()
    {
      if (!this.item.text)
        return '';
      if (this.deploymentMode)
        return this.item.text;
      let out = ` ${this.item.text}`.slice(1);
      out = out.replaceAll('\n', '<br>');
      const regex = /{{[^{}]*}}/g;
      if (this.item.text.match(regex))
        this.item.text.match(regex).forEach((match) => {
          const data = (match.match(/{{(.*)}}/)[1] || '').trim();
          if (this.eventData[data])
            out = out.replace(match, `<span class="badge bg-success">${data}</span>`);
          else
            out = out.replace(match, `<span class="badge bg-danger">${data}</span>`);
        });
      return out || '';
    },
    computedClass()
    {
      let out = '';
      if (this.item.alignment && this.item.alignment !== 'default')
        out += ` ${this.item.alignment}`;
      return out;
    },
    preComputedShrinkFactorExpression()
    {
      return `scale: calc(max(1.0 - (${this.shrinkFactor} * max(max({{ stringLength }} - ${this.shrinkCharQuantity}), 0) / {{ stringLength }}), 0)) ;transform-origin: center;display: flex; justify-content: center;`;
    },
    computedShrinkFactorExpression()
    {
      return 'line-height: 1.1;' + this.preComputedShrinkFactorExpression.replaceAll('{{ stringLength }}', this.text.length);
    },
    computedStyle()
    {
      const out = {};

      if (this.item.fontFamily)
        out.fontFamily = this.item.fontFamily;
      if (this.item.fontWeight)
        out.fontWeight = this.item.fontWeight;
      if (this.item.textTransform)
        out.textTransform = this.item.textTransform;

      const color = this.item.color;
      if (color)
      {
        if (color.hex8)
          out.color = color.hex8;
        else if (color.rgba)
          out.color = `rgba(
            ${color.rgba.r},
            ${color.rgba.g},
            ${color.rgba.b},
            ${color.rgba.a}
          )`;
      }

      const backgroundColor = this.item.backgroundColor;
      if (backgroundColor)
      {
        if (backgroundColor.hex8)
          out.backgroundColor = backgroundColor.hex8;
        else if (backgroundColor.rgba)
          out.backgroundColor = `rgba(
            ${backgroundColor.rgba.r},
            ${backgroundColor.rgba.g},
            ${backgroundColor.rgba.b},
            ${backgroundColor.rgba.a}
          )`;
      }

      if (this.item.fontSize)
        out.fontSize = this.item.fontSize;
      switch (this.item.textBehavior)
      {
        case 'overflow':
          out.overflow = 'visible';
          out.whiteSpace = 'nowrap';
          break;
        case 'decrease':
          out.overflow = 'visible';
          out.whiteSpace = 'nowrap';
          break;
        default:
          break;
      }
      if (this.item.textBehavior !== 'decrease')
        out.overflowWrap = 'anywhere';
      return out;
    },
  },
};

</script>

<style scoped>

</style>
