import Vue from 'vue';
import { uuid } from 'vue-uuid';

const Chance = require('chance');

/**
 * General information for mutations
 * use this way inside your components :
 * this.$store.commit('mutationName', { paramInPayload: Value, dbSync: [true;false] });
 * You must pass payload as an object containing your datas AND dbSync !
 * dbSync is used to tell the firebase worker to send the whole website content to firebase
 * if you do not define this key the data will not be synchronised and an error will be fired in the console
 */
const mutations = {
  /**
   * Set the current lang for the current builder session
   * @param state Vuex state (do not pass for users)
   * @param payload Mandatory fields : {
   *   lang: the builder language [fr;en]
   * }
   */
  setLang(state, payload)
  {
    state.lang = payload.lang;
  },
  /**
   * Add a Custom font to the website list.
   * An uuid will be created for this font
   * TODO rename from 'GoogleFont' to 'CustomFont' everywhere
   * @param state Vuex state (do not pass for users)
   * @param payload Mandatory fields: {
   *   fontObject: The new font Data
   * }
   */
  addGoogleFont(state, payload)
  {
    if (state.badgeContent.googleFonts === undefined)
      Vue.set(state.badgeContent, 'googleFonts', {});
    Vue.set(state.badgeContent.googleFonts, uuid.v4(), payload.fontObject);
  },
  /**
   * Remove a Custom font from the website list
   * identified by he's ID
   * @param state state Vuex state (do not pass for users)
   * @param payload Mandatory fields: {
   *   fontID: The ID of the removed font
   * }
   */
  deleteGoogleFont(state, payload)
  {
    Vue.delete(state.badgeContent.googleFonts, payload.fontID);
  },
  /**
   * Toggle the page loading screen
   * @param state state Vuex state (do not pass for users)
   * @param payload Mandatory fields: {
   *   value: Boolean [true;false]
   * }
   */
  toggleLoading(state, payload)
  {
    state.loading = payload.value;
  },
  /**
   * Toggle the error page
   * @param state state Vuex state (do not pass for users)
   * @param payload Mandatory fields: {
   *   error: true to show the page
   *   content: the error message
   * }
   */
  toggleLoadingError(state, payload)
  {
    state.loadingError = payload.error;
    state.lastError = payload.content;
  },
  /**
   * Initialize the worker data
   * datas are loaded from a cookie called WorkerData or the actual datas from state
   * @param state
   */
  initWorkerData(state)
  {
    if (state.workerData.id === '')
      state.workerData.id = uuid.v4();
    // eslint-disable-next-line no-underscore-dangle
    const previousData = this._vm.$cookies.get('WorkerData');
    if (previousData !== undefined && previousData !== null)
    {
      console.log(`Retrieving previous identity from Cookies : ${previousData.name}`);
    }
    if (state.workerData.name === '')
    {
      if (previousData !== undefined && previousData !== null)
      {
        state.workerData.name = previousData.name || 'Tricheur';
        state.workerData.initial = previousData.initial || 'TR';
      }
      else
      {
        const chance = new Chance();
        const first = chance.first();
        const animal = chance.animal();
        state.workerData.name = `${first} the ${animal}`;
        state.workerData.initial = `${first[0].toUpperCase()}${animal[0].toUpperCase()}`;
      }
    }
    if (state.workerData.color === undefined)
    {
      if (previousData !== undefined && previousData !== null && previousData.color !== undefined)
      {
        state.workerData.color = previousData.color || { primary: '#000000', secondary: '#000000' };
      }
      else
      {
        const chance = new Chance();
        state.workerData.color = {};
        state.workerData.color.primary = chance.color();
        state.workerData.color.secondary = chance.color();
      }
    }
    state.workerData.lastUpdate = Math.floor(Date.now());
    // eslint-disable-next-line no-underscore-dangle
    this._vm.$cookies.set('WorkerData', state.workerData);
  },
  /**
   * Set the worker name, this name defines the worker identity
   * for deployments and interactions with other workers
   * @param state Vuex state (do not pass for users)
   * @param payload = {
   *   name: the actual name, the initials are deduced from the name with a split on spaces
   * }
   */
  setWorkerName(state, payload)
  {
    state.workerData.name = payload.name;
    const wordArray = payload.name.split(' ');
    state.workerData.initial = '';
    wordArray.forEach((word) => {
      state.workerData.initial += `${word[0]}`;
    });
    // eslint-disable-next-line no-underscore-dangle
    this._vm.$cookies.set('WorkerData', state.workerData);
  },
  /**
   * Set the version number.
   * This value is displayed on the bottom of the advanced configuration
   * @param state Vuex state (do not pass for users)
   * @param payload = {
   *   version: the version number, a string
   * }
   */
  setVersionNumber(state, payload)
  {
    state.versionNumber = payload.version;
  },
  /**
   * Display the firebase sync spinner when set to true
   * @param state Vuex state (do not pass for users)
   * @param payload = {
   *   value: [true; false]
   * }
   */
  toggleFirebaseSpinner(state, payload)
  {
    state.firebaseSpinner = payload.value;
  },
  /**
   * Set the website content origin, this is used by the new website wizard
   * @param state Vuex state (do not pass for users)
   * @param payload = {
   *   origin: a string, usually [blank; template; import]
   * }
   */
  setBadgeContentOrigin(state, payload)
  {
    state.badgeContent.origin = payload.origin;
  },
  /**
   * By default, when the builder is browsed with a mobile device, an error page is shown
   * but this can bypass-ed by the boolean forceMobileAccess
   * this mutation is used to toggle it
   * @param state Vuex state (do not pass for users)
   * @param payload = {
   *   forceMobileAccess: Boolean [true; false]
   * }
   */
  setForceMobileAccess(state, payload)
  {
    state.forceMobileAccess = payload.forceMobileAccess;
  },
  /**
   * Set the badge full content
   * @param state Vuex state (do not pass for users)
   * @param payload = {
   *   content: Object, the content to set
   * }
   */
  setBadgeContent(state, payload)
  {
    state.badgeContent = payload.content;
  },
  setBadgeItems(state, payload)
  {
    state.badgeContent.items = payload.items;
  },
  setShowOverlays(state, payload)
  {
    state.showOverlays = payload.value;
  },
  setSnapToGrid(state, payload)
  {
    state.snapToGrid = payload.value;
  },
  setGridStep(state, payload)
  {
    state.gridStep = payload.value;
  },
  setKeepRatio(state, payload)
  {
    state.keepRatio = payload.value;
  },
  addItem(state, payload)
  {
    const items = state.badgeContent.items || [];
    if (payload.itemData) {
      items.push(payload.itemData);
      state.badgeContent = {
        ...state.badgeContent,
        items,
      };
    }
  },
  setSelectedItem(state, payload)
  {
    state.workerData.selectedItem = payload.selectedItem;
  },
  /**
   * Set the vlaue of the given item, item is pointed by he's ID
   * @param state
   * @param payload: {
   *   id: the ID of the item you want to edit
   *   value: the new full value
   * }
   */
  setItemValue(state, payload)
  {
    Vue.set(state.badgeContent.items, payload.id, payload.value);
  },

  setLastDeploymentID(state, payload)
  {
    state.lastDeploymentID = payload.id;
  },

  /**
   *
   * @param state
   * @param color: The color code you want to add to the palette
   */
  addColorToPalette(state, { color })
  {
    console.log(`add color to palette : ${color}`);
    if (!state.badgeContent.colorPalette)
      state.badgeContent.colorPalette = [];
    if (!state.badgeContent.colorPalette.includes(color))
      state.badgeContent.colorPalette.push(color);
  },
};

export default mutations;
