<template>
  <div class="row">
    <div v-if="this.coworkers.length > 3" style="margin-left: -5px;" class="col px-0">
      <button style="border: unset;height:32px;width:32px" class="btn btn-sm btn-secondary btn-raised rounded-circle bg-primary px-0"><i class="fas fa-ellipsis-h"></i></button>
    </div>
    <div v-for="w in limitedWorkerList.slice(-2)" :key="w.id" style="margin-left: -5px;" class="col px-0">
      <div v-if="workerData.id !== w.id">
        <button
            style="border: unset;height:32px;width:32px"
            :style="{ backgroundColor: w.color.primary }"
            class="btn btn-sm btn-secondary btn-raised rounded-circle px-0"
            @click="openWorkerContext(w)">{{ w.initial }}</button>
      </div>
      <WorkerInfoContext :ref="`worker-info-${w.id}`" :worker="w" :isYou="false"/>
    </div>
    <div class="col px-0" style="margin-left: -5px;">
      <button
          @click="$refs.youWorkerInfo.openContext()"
          style="border: unset;height:32px;width:32px"
          class="btn btn-sm btn-primary btn-raised rounded-circle px-0">
        {{ workerData.initial }}
      </button>
    </div>
    <WorkerInfoContext :worker="workerData" :isYou="true" ref="youWorkerInfo"/>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import WorkerInfoContext from '../contexts/WorkerInfoContext.vue';

export default {
  name: 'CoworkerIcons',
  components: { WorkerInfoContext },
  computed: {
    ...mapGetters(['coworkers', 'workerData']),
    limitedWorkerList()
    {
      const list = Object.values(this.coworkers);
      return list.filter((worker) => worker.id !== this.workerData.id);
    },
  },
  methods: {
    openWorkerContext(worker)
    {
      this.$refs[`worker-info-${worker.id}`][0].openContext();
    },
  },
};
</script>

<style scoped>

</style>
