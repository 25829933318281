/* eslint-disable no-shadow */// No shadow due to the getters with parameter

import DictionaryFrench from '../Dictionary/DictionaryFrench.json';
import DictionaryEnglish from '../Dictionary/DictionaryEnglish.json';
import defaultStyleValues from '../configuration/defaultStyleValues';

const getters = {
  // All the event datas
  eventData: (state) => state.eventData,
  // Is the page loading
  isLoading: (state) => state.loading,
  // Is there error on the website loading
  isLoadingError: (state) => state.loadingError,
  // Last loading error
  lastError: (state) => state.lastError,
  // Is there a website content
  isBadgeContent: (state) => !(state.badgeContent === undefined || Object.keys(state.badgeContent).length <= 0),
  // User defined fonts
  googleFonts: (state) => (state.badgeContent ? state.badgeContent.googleFonts || {} : {}),
  // This user datas
  workerData: (state) => state.workerData,
  // datas of all the peoples working on the website
  coworkers: (state) => state.coworkers,
  // Builder current language
  language: (state) => state.lang,
  // return the dictionary for the current builder language, used to translate the interface
  dictionary: (state) => {
    if (state.lang === 'fr')
      return DictionaryFrench;
    if (state.lang === 'en')
      return DictionaryEnglish;
    return {};
  },
  // Is the builder opened on mobile device
  isMobile: () => (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)),
  // Usually when the builder is opened in mobile device there is an error message,
  // but you can force the access through this boolean
  forceMobileAccess: (state) => state.forceMobileAccess,
  // Builder version number
  versionNumber: (state) => state.versionNumber,
  // if true the firebase sync widget is displayed
  firebaseSpinner: (state) => state.firebaseSpinner,
  // Live version of the website
  liveCommit: (state) => state.liveCommit,
  styleDefaultValues: () => defaultStyleValues,
  badgeContent: (state) => state.badgeContent,
  itemList: (state) => (state.badgeContent ? state.badgeContent.items || [] : []),
  zoomLevel: (state) => 50,
  showOverlays: (state) => state.showOverlays,
  snapToGrid: (state) => state.snapToGrid,
  keepRatio: (state) => state.keepRatio,
  gridStep: (state) => state.gridStep,
  // Selected Column ID
  selectedItemID: (state) => state.workerData.selectedItem,
  // Selected Column
  selectedItem: (state, getters) => {
    if (getters.itemList === undefined)
      return undefined;
    return getters.itemList[getters.selectedItemID];
  },

  // Convert a string to Slug
  stringToSlug: () => (str, isLowerCase = true) => {
    if (str === null || str === undefined)
      return '';
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    if (isLowerCase)
      str = str.toLowerCase();

    const from = 'àáãäâèéëêìíïîòóöôùúüûñç·/_,:;';
    const to = 'aaaaaeeeeiiiioooouuuunc-----';

    [...from].forEach((c, idx) => {
      str = str.replace(new RegExp(c, 'g'), to.charAt(idx));
    });

    str = str
      .replace(/\./g, '')
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-');

    return str;
  },
  // The color palette
  colorPalette: (state) => state.badgeContent.colorPalette || [],
  // have to remove this error because structured clone is defined by browser
  // eslint-disable-next-line no-undef
  cloneObject: () => (obj) => structuredClone(obj || {}),
};

export default getters;

/* eslint-enable no-shadow */
