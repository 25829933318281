/**
 * All these variables can be accessed through mutation and/or actions
 * do not access nor modify these directly!
 * If you don't find your way with mutation/actions it's because you're not supposed to edit the value
 */
const state = {
  lang: 'en',
  /// //////////////////// EDITOR STATE
  versionNumber: '',
  loading: true,
  loadingError: false,
  forceMobileAccess: false,
  lastError: {
    code: 0,
    message: '',
  },
  workerData: {
    name: '',
    id: '',
    lastUpdate: '',
    selectedItem: '',
  },
  coworkers: [],
  eventData: {},
  firebaseSpinner: false,
  liveCommit: {},
  lastDeploymentID: undefined,
  showOverlays: true,
  snapToGrid: false,
  gridStep: 20,
  keepRatio: false,
  /// //////////////////// WEBSITE JSON DATA
  websiteContent: {},
  badgeContent: {},
};

export default state;
