<template>
  <modal name="AddFontModal" :width="'80%'" :height="'90%'">
    <Card class="h-100">
      <template v-slot:header-left><h4 class="text-white mb-0">{{ dictionary.fontModal.modalTitle }}</h4></template>
      <template v-slot:header-right><button @click="close" type="button" class="btn-close btn-close-white" aria-label="Close"></button></template>
      <div v-if="!newFontMode" class="row justify-content-center h-100">
        <div class="col-12" style="height: 10%">
          <div class="row d-flex align-items-center">
            <div class="col-auto">
              <a class="btn btn-outline-secondary" href="https://fonts.google.com/" target="_blank">{{ dictionary.fontModal.goToGoogleFont }}</a>
            </div>
            <div class="col-auto ms-3">
              <button @click="newFontMode = true" type="button" class="btn btn-success btn-raised text-white"><i class="fas fa-plus-circle me-2"></i>{{ dictionary.fontModal.addCustomFont }}</button>
            </div>
            <div class="col-4 ms-auto">
              <div class="form-floating">
                <input v-model="searchQuery" type="text" class="form-control" id="searchInput" placeholder="Nom de la police">
                <label for="searchInput">{{ dictionary.fontModal.searchFont }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6" style="height: 90%" >
          <div class="col-12" style="height: 6%">
            <div class="row">
              <div class="col-auto">
                <h5 class="text-secondary">{{ dictionary.fontModal.googleFonts }}<i v-tooltip.top="`${dictionary.fontModal.googleFontsTooltip}`" class="ms-3 far fa-question-circle text-primary"></i></h5>
              </div>
              <div class="col">
                <hr>
              </div>
            </div>
          </div>
          <div class="col-12 overflow-hidden" style="height: 94%">
            <p v-if="!loadedGoogleFont" class="text-center text-muted">{{ dictionary.fontModal.googleFontsLoading }}</p>
            <perfect-scrollbar v-else class="h-100 pe-3" ref="googleFontsScrollContainer" v-bind:options="{ suppressScrollX: true, wheelPropagation: false }">
              <table class="table">
                <thead>
                <tr>
                  <th scope="col">{{ dictionary.fontModal.fontFamily }}</th>
                  <th scope="col">{{ dictionary.fontModal.fontCategory }}</th>
                  <th scope="col">{{ dictionary.fontModal.fontVariants }}</th>
                  <th scope="col">{{ dictionary.fontModal.fontImported }}</th>
                  <th scope="col">{{ dictionary.fontModal.fontActions }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(font, key) in slicedGoogleFonts" v-bind:key="key">
                  <td><h5>{{ font.family }}</h5></td>
                  <td>{{ font.category }}</td>
                  <td class="text-center" style="word-wrap: break-word;max-width:200px">
                    <span class="badge bg-secondary me-1">{{ font.variants.length }}</span>
                  </td>
                  <td class="text-center">
                    <span v-if="isFontImported(font)" class="badge bg-primary">{{ dictionary.yes }}</span>
                  </td>
                  <td class="d-flex justify-content-end h-100">
                    <a class="btn btn-info btn-raised" target="_blank" v-bind:href="`https://fonts.google.com/specimen/${font.family.replaceAll(' ', '+')}`"><i class="me-0 fas fa-eye me-2"></i><i class="fab fa-google"></i></a>
                    <button v-if="!isFontImported(font)" v-on:click="addGoogleFont(font)" class="btn btn-success btn-raised mx-1"><i class="me-0 fas fa-cloud-download-alt"><i class="ms-2 fas fa-chevron-right"></i></i></button>
                  </td>
                </tr>
                <tr>
                  <mugen-scroll scroll-container="googleFontsScrollContainer" :handler="fetchData" :should-handle="!mugenLoading">
                    <p v-if="scrolledEnd">{{ dictionary.fontModal.googleFontsEnd }}</p>
                  </mugen-scroll>
                </tr>
                </tbody>
              </table>
            </perfect-scrollbar>
          </div>
        </div>
        <div class="col-6" style="height: 90%">
          <div class="row h-100">
            <div class="col-12" style="height: 6%">
              <div class="row">
                <div class="col-auto">
                  <h5 class="text-secondary">{{ dictionary.fontModal.importedFonts }}</h5>
                </div>
                <div class="col" >
                  <hr>
                </div>
              </div>
            </div>
            <div class="col-12" style="height: 94%">
              <p v-if="fontListLength <= 0" class="text-muted text-center">{{ dictionary.fontModal.noFontForTheMoment }}</p>
              <perfect-scrollbar v-else :options="{ suppressScrollX: true, wheelPropagation: false }" class="h-75 pe-3" ref="googleFontsScrollContainer" >
                <table class="table">
                  <thead>
                  <tr>
                    <th scope="col">{{ dictionary.fontModal.source }}</th>
                    <th scope="col">{{ dictionary.fontModal.fontFamily }}</th>
                    <th scope="col"></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="font in Object.entries(fontList)" :key="font[0]">
                    <td>{{ font[1].source }}</td>
                    <td><h5>{{ font[1].family }}</h5></td>
                    <td colspan="1" class="d-flex justify-content-end h-100">
                      <button v-if="font[1].source === 'GoogleFont'" @click="removeFont(font[0])" class="btn btn-danger btn-raised mx-1"><i class="me-2 fas fa-chevron-left"></i><i class="me-0 fas fa-trash"></i></button>
                      <a v-if="font[1].source === 'GoogleFont'" :href="`https://fonts.google.com/specimen/${font[1].family.replaceAll(' ', '+')}`" class="btn btn-info btn-raised" target="_blank"><i class="me-0 fas fa-eye"></i></a>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </perfect-scrollbar>
            </div>
          </div>
        </div>
      </div>
      <FontFromFile v-else @cancel="newFontMode = false"/>
    </Card>
  </modal>
</template>

<script>

import MugenScroll from 'vue-mugen-scroll';
import { mapGetters } from 'vuex';
import VueNotifications from 'vue-notifications';
import Card from '../../Utilities/Card.vue';
import FontFromFile from './FontFromFile.vue';

export default {
  name: 'FontManager',
  components: {
    FontFromFile,
    Card,
    MugenScroll,
  },
  model: {
    prop: 'fontList',
    event: 'newFont',
  },
  props: {
    fontList: Object,
  },
  computed:
  {
    ...mapGetters(['dictionary']),
    fontListLength()
    {
      if (this.fontList === undefined)
        return 0;
      return Object.keys(this.fontList).length;
    },
    slicedGoogleFonts() {
      // eslint-disable-next-line arrow-body-style
      const filtered = this.googleFontList.filter((font) => {
        if (this.searchQuery !== undefined && this.searchQuery !== '')
          return font.family.toLowerCase().includes(this.searchQuery.toLowerCase());
        return font;
      });
      if (filtered.length > this.displayed)
        return filtered.slice(0, this.displayed);
      return filtered;
    },
  },
  data() {
    return {
      searchQuery: '',
      googleFontList: [],
      loadedGoogleFont: false,
      scrolledEnd: false,
      mugenLoading: false,
      displayed: 20,
      newFontMode: false,
    };
  },
  methods: {
    fetchData() {
      this.scrolledEnd = false;
      this.mugenLoading = true;
      if (this.googleFontList !== undefined && this.googleFontList.length > this.displayed)
        this.displayed += 10;
      else
        this.scrolledEnd = true;
      this.mugenLoading = false;
    },
    open()
    {
      this.newFontMode = false;
      this.$modal.show('AddFontModal');
      this.$store.dispatch('getGoogleFontList').then((items) => {
        this.googleFontList = items;
        this.loadedGoogleFont = true;
      });
    },
    close()
    {
      this.$modal.hide('AddFontModal');
    },
    computeGoogleFontURL(font)
    {
      let weightString = '';
      font.variants.forEach((variant) => {
        if (variant === 'regular')
          weightString += '0,400;';
        else if (variant !== 'italic' && !variant.includes('italic'))
          weightString += `0,${variant};`;
      });
      font.variants.forEach((variant) => {
        if (variant === 'italic')
          weightString += '1,400;';
        else if (variant.includes('italic'))
          weightString += `1,${variant.replace('italic', '')};`;
      });
      weightString = weightString.replace(/;+$/, '');
      return `https://fonts.googleapis.com/css2?family=${font.family.replace(' ', '+')}:ital,wght@${weightString}&display=swap`;
    },
    addGoogleFont(font)
    {
      const out = {};
      out.source = 'GoogleFont';
      out.GoogleFontURL = this.computeGoogleFontURL(font);
      out.family = font.family;
      if (font.category === 'sans-serif' || font.category === 'serif' || font.category === 'monospace')
        out.category = font.category;
      this.$store.commit('addGoogleFont', { fontObject: out, dbSync: true });
      this.fontSaveNotif();
    },
    isFontImported(font)
    {
      let found = false;
      Object.values(this.fontList).forEach((importedFont) => {
        if (importedFont.family === font.family)
          found = true;
      });
      return found;
    },
    removeFont(fontID)
    {
      this.$store.commit('deleteGoogleFont', { fontID, dbSync: true });
      this.fontRemoveNotif();
    },
  },
  notifications: {
    fontSaveNotif: {
      type: VueNotifications.types.success,
      title: 'Police importé',
      message: 'La police a bien été ajouté au registre !<br>Vous pouvez maintenant l\'utiliser',
    },
    fontRemoveNotif: {
      type: VueNotifications.types.success,
      title: 'Police supprimée',
      message: 'La police a bien été supprimée.',
    },
  },
};

</script>

<style scoped>

</style>
