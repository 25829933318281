<template>
  <StyleEditor v-model="style" :properties="styleProperties">
    <template v-slot:form-start>
      <id-editor v-model="name" placeholder="Pas de nom définit" title="Nom de l'élément"/>
      <hr>
      <div class="mb-3 row">
        <div class="col-auto pe-0">
          <label class="small">Position <span class="badge rounded-pill bg-primary">X</span><i class="fas fa-arrows-alt-h ms-2"></i></label>
        </div>
        <div class="col input-group">
          <input v-model="posX" type="number" step="0.5" class="form-control" >
          <span class="input-group-text"><i class="fas fa-percentage"></i></span>
        </div>
      </div>
      <div class="mb-3 row">
        <div class="col-auto pe-0">
          <label class="small">Position <span class="badge rounded-pill bg-secondary">Y</span><i class="fas fa-arrows-alt-v ms-2"></i></label>
        </div>
        <div class="col input-group">
          <input v-model="posY" type="number" step="0.5" class="form-control" >
          <span class="input-group-text"><i class="fas fa-percentage"></i></span>
        </div>
      </div>
      <div class="mb-3 row">
        <div class="col-auto pe-0">
          <label class="small">Hauteur</label>
        </div>
        <div class="col input-group">
          <input v-model="height" type="number" step="0.5" class="form-control">
          <span class="input-group-text"><i class="fas fa-percentage"></i></span>
        </div>
      </div>
      <div class="mb-3 row">
        <div class="col-auto pe-0">
          <label class="small">Largeur</label>
        </div>
        <div class="col input-group">
          <input v-model="width" type="number" step="0.5" class="form-control">
          <span class="input-group-text"><i class="fas fa-percentage"></i></span>
        </div>
      </div>
      <div class="mb-3">
        <div class="form-check form-switch">
          <label class="form-check-label" for="disablePrintCheck">Désactiver a l'impression</label>
          <input v-model="printDisabled" class="form-check-input" type="checkbox" id="disablePrintCheck">
        </div>
      </div>
      <hr>
      <TextConfig v-if="item.type === 'text'" :item="item" @change="$emit('change', $event)"/>
      <QrConfig v-if="item.type === 'qr-code'" :item="item" @change="$emit('change', $event)"/>
      <p class="text-muted small" v-if="item.type === 'text' && item.textBehavior === 'decrease'">L'alignement est obligatoirement centré en mode 'Diminuer la taille'</p>
    </template>
  </StyleEditor>
</template>

<script>

import StyleEditor from '@/components/Utilities/StyleEditor.vue';
import IdEditor from '@/components/Utilities/IdEditor.vue';
import TextConfig from '@/components/LeftBar/configs/TextConfig.vue';
import QrConfig from '@/components/LeftBar/configs/QrConfig.vue';

export default {
  name: 'ItemConfig',
  components: {
    QrConfig,
    TextConfig,
    IdEditor,
    StyleEditor,
  },
  model: {
    prop: 'item',
    event: 'change',
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    name: {
      get()
      {
        return this.item.name || '';
      },
      set(newName)
      {
        this.$emit('change', { ...this.item, name: newName });
      },
    },
    style:
    {
      get()
      {
        return this.item || {};
      },
      set(newStyle)
      {
        this.$emit('change', { ...this.item, ...newStyle });
      },
    },
    styleProperties()
    {
      if (this.item.type === 'text')
      {
        const out = [
          'hr',
          'color',
          'fontFamily',
          'fontWeight',
          'fontSize',
          'hr',
          'backgroundColor',
          'alignment',
        ];
        if (this.item.textBehavior !== 'decrease')
          out.unshift('alignment');
        return out;
      }
      if (this.item.type === 'image')
        return ['backgroundColor', 'backgroundImage'];
      return [];
    },
    posX: {
      get()
      {
        if (this.item.position)
          return this.item.position.percent.x;
        return 0;
      },
      set(newPosX)
      {
        this.$emit('change', { ...this.item, position: { percent: { ...this.item.position.percent, x: parseFloat(newPosX) } } });
        this.$emit('forceUpdate');
      },
    },
    posY: {
      get()
      {
        if (this.item.position)
          return this.item.position.percent.y;
        return 0;
      },
      set(newPosY)
      {
        this.$emit('change', { ...this.item, position: { percent: { ...this.item.position.percent, y: parseFloat(newPosY) } } });
        this.$emit('forceUpdate');
      },
    },
    height: {
      get()
      {
        if (this.item.position)
          return this.item.position.percent.h;
        return 0;
      },
      set(newHeight)
      {
        this.$emit('change', { ...this.item, position: { percent: { ...this.item.position.percent, h: parseFloat(newHeight) } } });
        this.$emit('forceUpdate');
      },
    },
    width: {
      get()
      {
        if (this.item.position)
          return this.item.position.percent.w;
        return 0;
      },
      set(newWidth)
      {
        this.$emit('change', { ...this.item, position: { percent: { ...this.item.position.percent, w: parseFloat(newWidth) } } });
        this.$emit('forceUpdate');
      },
    },
    itemSize: {
      get()
      {
        return this.item.position || {
          x: 0,
          y: 0,
          h: 0,
          w: 0,
        };
      },
      set(newPosition)
      {
        this.$emit('change', { ...this.item, position: newPosition });
      },
    },
    printDisabled: {
      get()
      {
        return this.item.printDisabled || false;
      },
      set(newValue)
      {
        this.$emit('change', { ...this.item, printDisabled: newValue });
      },
    },
  },
};

</script>

<style scoped>

</style>
