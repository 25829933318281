<template>
  <!-- Preview with editor code -->
  <div v-if="!deploymentMode" class="container-badge" ref="badgeContent">
    <div class="ratio mx-auto shadow-lg position-relative" :style="cardStyle" @click.stop.prevent ref="badgeBackground">
      <img v-if="cardBackground" class="position-absolute top-0 left-0 w-100 h-100" :src="cardBackground" alt="">
      <div class="h-100 w-100" @click="itemClick(-1);"></div>
      <div v-if="snapToGrid" class="h-100 w-100" :style="gridStyle" style="pointer-events: none;"></div>
      <div v-if="isLoaded && itemList && itemList.length <= 0" class="w-100 h-100">
        <div class="row mt-5">
          <div class="col-auto mx-auto mt-5 text-center">
            <h3 class="text-muted">Le badge ne comporte pas encore d'éléments</h3>
            <h4 class="text-muted">Cliquez ci-dessous ou sur le menu lateral pour en ajouter</h4>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-auto text-center ms-auto">
            <button @click="addItems('text')" class="btn btn-outline-secondary py-3 px-4 fw-bold"><i class="fas fa-font fa-3x pb-2"></i><br>Ajouter un Texte</button>
          </div>
          <div class="col-auto text-center">
            <button @click="addItems('image')" class="btn btn-outline-secondary py-3 px-4 fw-bold"><i class="fas fa-photo-video fa-3x pb-2"></i><br>Ajouter une Image</button>
          </div>
          <div class="col-auto text-center me-auto">
            <button @click="addItems('qr-code')" class="btn btn-outline-secondary py-3 px-4 fw-bold"><i class="fas fa-qrcode fa-3x pb-2"></i><br>Ajouter un Qr code</button>
          </div>
        </div>
      </div>
      <template v-else-if="isLoaded">
        <div class="oeillet" v-if="this.badgeContent.origin === 'papillon'"></div>
        <VueDragResize
            v-for="(item, itemID) in itemList"
            :key="`item-${itemID}`"
            :minh="10"
            :minw="10"
            :parentLimitation="true"
            :parentW="$refs.badgeBackground.clientWidth"
            :parentH="$refs.badgeBackground.clientHeight"
            :y="getItemSize(item).y"
            :x="getItemSize(item).x"
            :snapToGrid="snapToGrid || false"
            :w="getItemSize(item).w"
            :h="getItemSize(item).h"
            :isActive="selectedItemID === itemID"
            :gridX="gridStep"
            :gridY="gridStep"
            :class="showOverlays ? 'itemOverlay' : ''"
            :stickSize="10"
            :aspectRatio="restrainProportions"
            @resizestop="resize($event, itemID)"
            @dragstop="resize($event, itemID)"
            @activated="itemClick(itemID)"
            class="badgeItem">
          <TextComponent v-if="item.type === 'text'" :id="`item-${itemID}`" :item="item"/>
          <ImageComponent v-if="item.type === 'image'" :id="`item-${itemID}`" :item="item" />
          <QrComponent v-if="item.type === 'qr-code'" :id="`item-${itemID}`" :item="item"/>
        </VueDragResize>
      </template>
    </div>
  </div>
  <!-- Deployed Badge Code -->
  <div v-else class="position-relative h-100 w-100">
    <img v-if="cardBackground" class="position-absolute top-0 left-0 w-100 h-100" :src="cardBackground" alt="">
    <div class="position-absolute"
        v-for="(item, itemID) in itemList" :key="`item-${itemID}`"
        :print-disable="item.printDisabled === true ? 'True' : undefined"
        :style="`width: ${item.position ? item.position.percent.w || 0 : 0}%; height: ${item.position ? item.position.percent.h || 0 : 0}%; top: ${item.position ? item.position.percent.y || 0 : 0}%; left: ${item.position ? item.position.percent.x || 0 : 0}%;`">
      <TextComponent v-if="item.type === 'text'" :id="`item-${itemID}`" :item="item" :deploymentMode="deploymentMode"/>
      <ImageComponent v-if="item.type === 'image'" :id="`item-${itemID}`" :item="item" :deploymentMode="deploymentMode"/>
      <QrComponent v-if="item.type === 'qr-code'" :id="`item-${itemID}`" :item="item" :deploymentMode="deploymentMode"/>
    </div>
  </div>
</template>

<script>

import _ from 'lodash';
import { mapGetters } from 'vuex';
import VueDragResize from 'vue-drag-resize';
import ImageComponent from '@/components/Content/ImageComponent.vue';
import TextComponent from './Content/TextComponent.vue';
import QrComponent from './Content/QrComponent.vue';

export default {
  name: 'BadgePreview',
  components: {
    QrComponent,
    ImageComponent,
    VueDragResize,
    TextComponent,
  },
  props: {
    deploymentMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mounted()
  {
    this.$nextTick(() => {
      this.isLoaded = true;
    });
    this.$store.watch((__, getters) => getters.googleFonts, () => {
      this.updateFonts();
    });
    this.updateFonts();
  },
  data()
  {
    return {
      isLoaded: false,
      forceKeepRatio: false,
    };
  },
  computed: {
    ...mapGetters([
      'badgeContent',
      'zoomLevel',
      'itemList',
      'selectedItemID',
      'showOverlays',
      'gridStep',
      'snapToGrid',
      'keepRatio']),
    cardStyle()
    {
      const out = {};
      out.width = '100%';
      if (this.badgeContent.origin === 'CR80')
        out.borderRadius = '8px';
      if (this.badgeContent.origin === 'papillon') {
        out.borderRadius = '8px';
      }
      out.backgroundColor = this.badgeContent.backgroundColor.hex8 || 'white';
      out['--bs-aspect-ratio'] = `${(this.badgeContent.orientation === 'v') ? this.badgeContent.format.ratioY : this.badgeContent.format.ratioX}%`;
      return out;
    },
    cardBackground()
    {
      return this.badgeContent.backgroundImage || undefined;
    },
    gridStyle()
    {
      const out = {};
      out.backgroundImage = 'repeating-linear-gradient(#ddd 0 1px, transparent 1px 100%), repeating-linear-gradient(90deg, #ddd 0 1px, transparent 1px 100%)';
      out.backgroundSize = `${this.gridStep}px ${this.gridStep}px`;
      return out;
    },
    restrainProportions()
    {
      return this.keepRatio || this.forceKeepRatio;
    },
  },
  methods: {
    updateFonts()
    {
      if (this.deploymentMode)
        return;
      let fontsCSS = '';
      this.$store.dispatch('getImportedFontCSS').then((fonts) => {
        fontsCSS = fonts;
        const head = this.$refs.badgeContent;
        if (head === undefined || head === null)
          return;
        const style = document.createElement('style');
        style.id = 'fontsList';
        const oldNode = document.getElementById('fontsList');
        if (oldNode !== null && head.contains(oldNode))
          head.removeChild(oldNode);
        if (fontsCSS === '')
          return;
        head.appendChild(style);
        style.type = 'text/css';
        style.appendChild(document.createTextNode(fontsCSS));
      });
    },
    reload()
    {
      this.isLoaded = false;
      this.itemList.forEach((item) => {
        if (item.position)
          item.position = { percent: item.position.percent };
      });
      this.$nextTick(() => {
        this.isLoaded = true;
      });
    },
    getItemSize(item)
    {
      if (!item.position)
        return {
          x: 100,
          y: 100,
          h: 100,
          w: 100,
        };

      const VPH = this.$refs.badgeBackground.clientHeight;
      const VPW = this.$refs.badgeBackground.clientWidth;

      if (!item.position.x)
        item.position.x = ((VPW * (item.position.percent.x)) / 100);
      if (!item.position.y)
        item.position.y = ((VPH * (item.position.percent.y)) / 100);
      if (!item.position.w)
        item.position.w = ((VPW * (item.position.percent.w)) / 100);
      if (!item.position.h)
        item.position.h = ((VPH * (item.position.percent.h)) / 100);

      return item.position;
    },
    itemClick(itemID)
    {
      this.$store.commit('setSelectedItem', { selectedItem: itemID, dbSync: true });
      this.$EventBus.$emit('editItem', itemID);
    },
    resize(event, idx) {
      const itemsTmp = _.cloneDeep(this.itemList);
      itemsTmp[idx].position = {
        ...itemsTmp[idx].position || {},
        percent: {
          h: ((100 * event.height) / this.$refs.badgeBackground.clientHeight).toFixed(6),
          w: ((100 * event.width) / this.$refs.badgeBackground.clientWidth).toFixed(6),
          x: ((100 * event.left) / this.$refs.badgeBackground.clientWidth).toFixed(6),
          y: ((100 * event.top) / this.$refs.badgeBackground.clientHeight).toFixed(6),
        },
      };
      this.$store.commit('setBadgeContent', { content: { ...this.badgeContent, items: itemsTmp }, dbSync: true });
    },
    addItems(itemType)
    {
      const id = this.$chance.string({
        length: 5,
        casing: 'upper',
        alpha: true,
        numeric: false,
      });
      const itemData = { type: itemType, name: `${itemType}-${id}` };
      if (itemType === 'text')
        itemData.text = 'Votre texte ici';
      if (itemType === 'image')
        itemData.backgroundImage = 'https://via.placeholder.com/200x200.png?text=Votre%20image%20ici';
      this.$store.commit('addItem', { itemData, dbSync: true });
      this.$store.commit('setSelectedItem', { selectedItem: this.itemList.length - 1, dbSync: true });
      this.$EventBus.$emit('editItem', this.itemList.length - 1);
    },
  },
};

</script>

<style scoped>
.oeillet{
  background: black;
  z-index: 1;
  position: absolute;
  top: 40px;
  margin: 0 auto;
  display: block;
  width: 155px;
  height: 50px;
  border-radius: 25px;
  text-align: center;
  right: 0;
  left: 0;
}
.badgeItem {
  height: unset;
  width: unset;
}

.itemOverlay {
  border: 1px dashed var(--bs-secondary);
}

.itemOverlay.active {
  border: 2px dashed var(--bs-success);
  z-index: 100!important;
}

.vdr.active::before {
  outline: unset!important;
}

</style>

<style>

</style>
