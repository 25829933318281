<template>
  <div>
    <label class="small">{{ label }}</label>
    <select :value="value" @input="$emit('change', $event.target.value)" class="form-select">
      <option value="lighter">{{ dictionary.fontWeightSelector.thinText }}</option>
      <option value="" selected>{{ dictionary.fontWeightSelector.regularText }}</option>
      <option value="bold">{{ dictionary.fontWeightSelector.boldText }}</option>
      <option value="bolder">{{ dictionary.fontWeightSelector.bolderText }}</option>
    </select>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'FontWeightSelector',
  props: {
    value: String,
    label: String,
  },
  model: {
    model: 'value',
    event: 'change',
  },
  computed: {
    ...mapGetters(['dictionary']),
  },
};

</script>

<style scoped>

</style>
