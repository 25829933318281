import { firebaseAction } from 'vuexfire';
import firebaseReference from '../FirebaseReference';

export default {
  /**
   * Set the "live" (production) website from the payload information
   * @param payload: {
   *  html: the website final html content
   *  commitId: the corresponding commit ID
   *  customCSS: the custom CSS
   *  customJS: the custom Javascript
   *  mainCSS: the CSS generated by the builder (html/body style)
   * }
   */
  setLiveBadge: firebaseAction(async function callback({ commit }, payload)
  {
    commit('setLastDeploymentID', { id: payload.id });
    console.log('set current website to', payload.id);
    const fbRefRoot = await firebaseReference.root;
    return fbRefRoot.child('live').set({
      html: payload.html || '',
      mainCSS: payload.mainCss || '',
      commitId: payload.id || '',
      badgeContent: payload.badgeContent || {},
    });
  }),

  /**
   * Add a commit to the website history based on the payload
   * @param payload: {
   *   // no specific field except :
   *   date: the date when the commit is created
   * }
   */
  addCommitToHistory: firebaseAction(async function callback(_, payload)
  {
    console.log('Add Commit to history', payload.date);
    const fbRefRoot = await firebaseReference.root;
    return fbRefRoot.child('websiteHistory').child(payload.date).set(payload);
  }),

  /**
   * Deploy the given website content (payload) to production by calling :
   * addCommitToHistory and setLiveWebsite one after the other
   * @param dispatch
   * @param payload // the whole badge content
   */
  deployBadgeContent({ dispatch }, payload)
  {
    console.log('Deploying to production', payload.id);
    dispatch('addCommitToHistory', payload).then(() => dispatch('setLiveBadge', payload));
  },

  /**
   * Return website's deployment history
   * @returns All the past deployment
   */
  async getBadgeHistory()
  {
    let value = {};
    const fbRefRoot = await firebaseReference.root;
    await fbRefRoot.child('websiteHistory').once('value', (snapshot) => { value = snapshot.val(); });
    return value;
  },

};
