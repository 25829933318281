<template>
  <div class="w-100 h-100 overflow-hidden" :style="`background-color: ${qrColor};`">
    <img :src="QrUrl" alt="" class="img-fluid w-100" style="mix-blend-mode: lighten">
  </div>
</template>

<script>

export default {
  name: 'QrComponent',
  props: {
    id: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    deploymentMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    qrColor()
    {
      if (this.item.color)
        return this.item.color.hex || 'black';
      return 'black';
    },
    encodedData()
    {
      switch (this.item.contentType)
      {
        case 'userID': return '{{id}}';
        case 'userCode': return '{{qrCode}}';
        case 'brutValue': return this.customEncodeURIComponent(this.item.contentText) || '';
        default: return '{{qrCode}}';
      }
    },
    complexityLevel()
    {
      return this.item.complexity || 'L';
    },
    margin()
    {
      return this.item.margin || 4;
    },
    size()
    {
      return this.item.size || 10;
    },
    QrUrl()
    {
      return `https://europe-west1-captag-events.cloudfunctions.net/utils/qrcode/${this.encodedData}/?complexity=${this.complexityLevel}&margin=${this.margin}&size=${this.size}`;
    },
  },
  methods: {
    customEncodeURIComponent(input) {
      let encodedURL = encodeURIComponent(input);
      const regex = /%7B%7B([^%]+)%7D%7D/g;
      encodedURL = encodedURL.replace(regex, (_, match) => `{{${match}}}`);
      return encodedURL;
    },
  },
};

</script>

<style scoped>

</style>
