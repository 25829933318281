import { firebaseAction } from 'vuexfire';
import firebase from 'firebase/app';
import firebaseReference from '../FirebaseReference';

export default {
  /**
   * Update the worker data from the state
   * called by a setInterval located in bindToFirebase
   */
  setWorkerData: firebaseAction(async function callback({ getters })
  {
    if (getters.workerData.id)
    {
      const fbRefRoot = await firebaseReference.root;
      fbRefRoot.child('coworkers').child(getters.workerData.id).update({ ...getters.workerData, lastUpdate: firebase.database.ServerValue.TIMESTAMP });
    }
  }),
  /**
   * Remove a worker in co-worker list based on he's ID
   * @param payload: must contain: {
   *   workerID: the ID of the removed worker
   * }
   */
  removeCoWorker: firebaseAction(async function callback(_, payload)
  {
    const fbRefRoot = await firebaseReference.root;
    return fbRefRoot.child('coworkers').child(payload.workerID).remove();
  }),
  /**
   * Remove all the worker not updating the base since more than 5000ms
   * @param dispatch
   * @param state
   */
  removeOldWorker({ dispatch, state })
  {
    Object.entries(state.coworkers).forEach(([, value]) => {
      if (Date.now() - value.lastUpdate > 5000)
        dispatch('removeCoWorker', { workerID: value.id });
    });
  },
};
