<template>
  <div :class="folded ? 'sidebar-folded' : 'sidebar-expanded'" class="sidebar bg-light position-relative">
    <button v-if="!folded" @click="toggleFold" class="position-absolute btn btn-primary btn-raised" style="top: 5px; right: -45px;z-index: 1000">
      <i class="fa fa-chevron-left pull-right text-dark" ></i>
    </button>
    <Tabs v-if="!folded" v-bind:tabs="getTabs" class="pt-1 h-75" ref="tabs">
      <template v-slot:slot-0 v-if="!folded">
        <card title="Configuration du badge" class="h-100">
          <perfect-scrollbar class="h-100 pe-3" :options="{ suppressScrollX: true, wheelPropagation: false }">
            <BodyConfig style="overflow-x: hidden" class="me-0"/>
          </perfect-scrollbar>
        </card>
      </template>
      <template v-slot:slot-1 v-if="!folded">
        <card title="Ajouter un élément" class="h-100">
          <perfect-scrollbar :options="{ suppressScrollX: true, wheelPropagation: false }" class="h-100 pe-3">
            <NewItems/>
            <hr>
            <ItemList @forceUpdate="$emit('update')" />
          </perfect-scrollbar>
        </card>
      </template>
      <template v-slot:slot-2 v-if="!folded">
        <card title="Configuration" class="h-100">
          <perfect-scrollbar class="h-100 pe-3" :options="{ suppressScrollX: true, wheelPropagation: false }">
            <template v-if="selectedItem !== undefined">
              <ItemConfig :item="itemList[selectedItemID]" @change="$store.commit('setItemValue', { id: selectedItemID, value: $event });" @forceUpdate="$emit('update')" />
            </template>
            <p v-else class="text-center small text-muted">Séléctionnez un element pour l'éditer</p>
          </perfect-scrollbar>
        </card>
      </template>
    </Tabs>
    <div v-if="!folded" class="h-25 px-3">
      <AdvancedConfiguration/>
    </div>
    <div v-else>
      <div class="text-center">
        <button @click="toggleFold" class="my-3 btn btn-primary btn-raised pt-2" type="button">
          <i class="fa fa-chevron-right pull-right text-dark"></i>
        </button>
        <div @click="toggleFold" class="card-header text-center px-0 bg-secondary pt-3 cursor-pointer">
          <h4 class="fa fa-pager text-light" ></h4>
        </div>
        <div @click="toggleFold" class="card-header text-center px-0 bg-secondary pt-3 cursor-pointer">
          <h4 class="fa fa-grip-lines text-light"></h4>
        </div>
        <div @click="toggleFold" class="card-header text-center px-0 bg-secondary pt-3 cursor-pointer">
          <h4 class="fa fa-columns text-light"></h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import ItemConfig from '@/components/LeftBar/configs/ItemConfig.vue';
import ItemList from '@/components/LeftBar/configs/ItemList.vue';
import BodyConfig from './configs/BodyConfig.vue';
import AdvancedConfiguration from './configs/AdvancedConfiguration.vue';
import NewItems from './configs/NewItems.vue';
import Tabs from '../Utilities/Tabs.vue';
import Card from '../Utilities/Card.vue';

export default {
  name: 'LeftBar',
  components: {
    ItemList,
    ItemConfig,
    AdvancedConfiguration,
    BodyConfig,
    Tabs,
    Card,
    NewItems,
  },
  data() {
    return {
      currentTab: 0,
      folded: false,
      mutationSubscriber: undefined,
    };
  },
  methods: {
    toggleFold()
    {
      this.folded = !this.folded;
    },
    onEditItem(col)
    {
      if (col !== -1)
        this.$refs.tabs.setActiveTab(2);
      else
        this.$refs.tabs.setActiveTab(1);
    },
  },
  mounted() {
    this.$EventBus.$on('editItem', this.onEditItem);
  },
  beforeDestroy() {
    if (this.mutationSubscriber !== undefined)
      this.mutationSubscriber();
    this.$EventBus.$off('editItem', this.onEditItem);
  },
  computed: {
    ...mapGetters(['dictionary', 'workerData', 'selectedItem', 'selectedItemID', 'itemList']),
    getTabs() {
      return [
        '<p class="text-secondary pb-0 mb-0"><span class="fa fa-pager"></span><br><span class="small">Configuration</span></p>',
        '<p class="text-secondary pb-0 mb-0"><span class="fa fa-plus-circle"></span><br><span class="small">Nouveau</span></p>',
        '<p class="text-secondary pb-0 mb-0"><span class="fa fa-cogs"></span><br><span class="small">Contenus</span></p>',
      ];
    },
  },
};

</script>

<style scoped>

.sidebar {
  padding: 0;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.2),0 1px 5px 0 rgba(0,0,0,.12);
}

.sidebar-expanded {
  transition: max-height 0.15s ease-out;
  transition-property: width;
}

.sidebar-folded {
  transition: max-height 0.25s ease-out;
  transition-duration: 0.15s;
  transition-property: width;
}

</style>
