<template>
  <div class="row h-100">
    <div v-if="label" class="col-auto">
      <label class="small ">{{ label }}</label>
    </div>
    <div class="col input-group d-flex colorGroup px-0 mx-2 colorShadow" style="min-height: 25px;">
      <div @click="openPicker" class="flex-grow-1 backgroundPreview">
        <div :style="colorPreview" class="h-100 w-100 colorPreviewBox"></div>
      </div>
      <button @click="openPalette" class="btn btn-success text-light"><i class="fas fa-palette"></i></button>
    </div>
    <context-menu @ctx-cancel="saveColorToPalette" @ctx-close="onClosePicker" :ref="`${computedID}ctxColorPicker`" id="context-menu-picker" class="colorContext picker">
      <sketch v-model="color"/>
    </context-menu>
    <context-menu @ctx-close="onClosePalette"  id="context-menu-picker" :ref="`${computedID}ctxColorPalette`" class="colorContext palette">
      <div style="width: 180px">
        <div class="row p-0 m-0 d-flex justify-content-center w-100" >
          <div class="pe-2 col-auto p-0 pt-1" v-for="(color, colorIdx) in defaultColors" :key="colorIdx" :class="colorIdx === 0 || colorIdx % 5 === 0 ? 'ps-2' : ''">
            <div @click="setColor(color)" @mouseover="currentPaletteVal = color" class="backgroundPreview colorShadow" style="cursor: pointer; width: 25px; height: 25px; border-radius: 5px;" >
              <div :style="{ backgroundColor: color }" class="h-100 w-100 colorPaletteButton"></div>
            </div>
          </div>
          <div class="pe-2 col-auto p-0 pt-1" v-for="fillIdx in 5 - (defaultColors.length % 5)" :key="`filling-${fillIdx}`">
            <div :style="{ backgroundColor: 'transparent', width: '25px', height: '25px' }"></div>
          </div>
        </div>
        <div class="row">
          <div class="col-9 mx-auto"><hr></div>
        </div>
        <div class="row p-0 m-0 d-flex justify-content-center w-100" >
          <div class="pe-2 col-auto p-0 pt-1" v-for="(color, colorIdx) in colorPalette" :key="colorIdx" :class="colorIdx === 0 || colorIdx % 5 === 0 ? 'ps-2' : ''">
            <div @click="setColor(color)" @mouseover="currentPaletteVal = color" class="backgroundPreview colorShadow" style="cursor: pointer; width: 25px; height: 25px; border-radius: 5px;" >
              <div class="h-100 w-100 colorPaletteButton" :style="{ backgroundColor: color }"></div>
            </div>
          </div>
          <div class="pe-2 col-auto p-0 pt-1" v-for="fillIdx in 5 - (colorPalette.length % 5)" :key="`filling-${fillIdx}`">
            <div :style="{ backgroundColor: 'transparent', width: '25px', height: '25px' }"></div>
          </div>
        </div>
        <div class="row p-0 m-0 mt-3 mb-2 w-100">
          <div class="col-11 mx-auto">
            <input class="w-100 form-control" readonly type="text" :value="currentPaletteVal">
          </div>
        </div>
      </div>
    </context-menu>
  </div>
</template>

<script>

import VueColor from 'vue-color';
import contextMenu from 'vue-context-menu';
import { mapGetters } from 'vuex';
import DefaultColorPalette from '../../configuration/DefaultColorPalette.json';

export default {
  name: 'InputColorPicker',
  components: {
    Sketch: VueColor.Chrome,
    contextMenu,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      ID: '',
      currentPaletteVal: '',
    };
  },
  methods: {
    openPicker()
    {
      this.$refs[`${this.computedID}ctxColorPicker`].open();
    },
    onClosePicker()
    {
      this.$refs[`${this.computedID}ctxColorPicker`].ctxVisible = true;
    },
    openPalette()
    {
      this.$refs[`${this.computedID}ctxColorPalette`].open();
    },
    onClosePalette()
    {
      this.$refs[`${this.computedID}ctxColorPalette`].ctxVisible = true;
    },
    saveColorToPalette()
    {
      if (this.color.hex8)
        this.$store.commit('addColorToPalette', { color: this.color.hex8, dbSync: true });
    },
    setColor(color)
    {
      this.color = { hex8: color };
    },
  },
  computed: {
    ...mapGetters(['colorPalette']),
    defaultColors()
    {
      return DefaultColorPalette || [];
    },
    computedID() {
      if (this.ID === '')
      {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.ID = this.$chance.string({
          length: 5,
          casing: 'upper',
          alpha: true,
          numeric: false,
        });
      }
      return this.ID;
    },
    colorPreview() {
      if (this.value.hex8 !== undefined)
        return { backgroundColor: this.value.hex8 };
      if (this.value.hex !== undefined)
        return { backgroundColor: this.value.hex };
      if (this.value.rgba !== undefined)
        return { backgroundColor: `rgba(${this.value.rgba.r}, ${this.value.rgba.g}, ${this.value.rgba.b}, ${this.value.rgba.a}` };
      return { backgroundColor: 'unset' };
    },
    color: {
      get()
      {
        return this.value;
      },
      set(newColor)
      {
        this.$emit('change', newColor);
      },
    },
  },
};

</script>

<style scoped>

.colorShadow {
  box-shadow: 3px 3px 2px -1px rgba(0,0,0,0.70);
  -webkit-box-shadow: 3px 3px 2px -1px rgba(0,0,0,0.70);
}

.colorGroup {
  border-radius: 0.25rem;
}

.backgroundPreview {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAIUlEQVQ4jWN49uzZf3yYEGAYNWBYGEBIASELRg0YFgYAABpE1q4luwPVAAAAAElFTkSuQmCC");
  cursor: pointer;
}

.colorPreviewBox {
  cursor: pointer;
  border-radius: 0.25rem 0 0 0.25rem;
}

.colorPreviewBox:hover {
  border: solid 1px grey;
}

.ctx-menu-container {
  border: unset;
}

.ctx-menu {
  min-width: unset!important;
}

.colorContext {
  width: 300px;
}

.colorPaletteButton {
  border-radius: 5px;
}

.colorPaletteButton:hover {
  border: 1px solid black;
}

</style>
