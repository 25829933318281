<template>
  <div>
    <form @submit.stop.prevent="deploy">
      <div class="mb-3">
        <label class="small fw-bold" for="senderName">{{ dictionary.author }}</label>
        <input v-model="commit.author" :placeholder="workerData.name" readonly required id="senderName" type="text" class="form-control required readonly" >
      </div>
      <div class="mb-3">
        <label class="small fw-bold " for="timestamp">{{ dictionary.deploymentModal.deployTabDate }}</label>
        <input :value="formatDate" disabled id="timestamp" type="text" class="form-control required">
      </div>
      <div class="mb-3">
        <label class="small fw-bold ">{{ dictionary.message }}</label>
        <textarea v-model="commit.message" name="message" type="text" class="form-control" placeholder="Pour garder une trace de vos modifications ..."></textarea>
      </div>
      <div class="row justify-content-center">
        <div class="col-auto">
          <button :disabled="!commitUpdated" :class="commitUpdated ? '' : 'disabled'" class="btn btn-raised text-light fw-bold btn-success"><i v-if="!commitUpdated" class="fas fa-circle-notch fa-spin me-2"></i>{{ dictionary.deploy }}</button>
        </div>
      </div>
    </form>
    <div style="visibility: hidden">
      <BadgePreview ref="finalHTML" v-bind:deploymentMode="true"/>
    </div>
  </div>
</template>

<script>

import VueNotifications from 'vue-notifications';
import { mapGetters, mapState } from 'vuex';
import crypto from 'crypto';
import { uuid } from 'vue-uuid';
import BadgePreview from '@/components/BadgePreview.vue';

export default {
  name: 'NewDeployment',
  components: {
    BadgePreview,
  },
  data() {
    return {
      commitUpdated: false,
      commit: {
        id: '',
        author: '',
        message: '',
        content: '',
        date: 0,
        checksum: '',
        badgeContent: {},
      },
    };
  },
  mounted()
  {
    this.updateCommitData().then(() => { this.commitUpdated = true; });
  },
  computed: {
    ...mapGetters(['dictionary', 'workerData', 'badgeContent']),
    ...mapState(['badgeContent']),
    formatDate()
    {
      return this.$formatDate(new Date());
    },
  },
  methods: {
    async updateCommitData()
    {
      this.commitUpdated = false;
      this.commit.date = Date.now();
      this.commit.author = this.workerData.name;
      this.commit.badgeContent = { ...this.badgeContent, items: this.getCleanItemList(this.badgeContent.items) };
      this.commit.checksum = this.checksum(JSON.stringify(this.commit.badgeContent));
    },
    getCleanItemList(items)
    {
      const itemsTmp = this.$store.getters.cloneObject(items);
      if (!itemsTmp) return [];
      itemsTmp.forEach((item) => {
        if (!item.position || !item.position.percent)
          return;
        item.position = { percent: { ...item.position.percent } };
      });
      return itemsTmp;
    },
    checksum(data)
    {
      const options = {
        algorithm: 'sha1',
        encoding: 'hex',
      };
      const hash = crypto.createHash(options.algorithm);
      if (!hash.write)
        hash.update(data);// pre-streaming crypto API in node < v0.9
      else
        hash.write(data);
      return hash.digest(options.encoding);
    },
    deploy()
    {
      this.commit.id = uuid.v4();
      let cleanHtml = this.$refs.finalHTML.$el.outerHTML;
      cleanHtml = cleanHtml.replace(/data-v-[0-9a-zA-Z]*/g, '');
      cleanHtml = cleanHtml.replace('data-filter=""', '');
      this.$store.dispatch('getBodyCSS').then((bodyCSS) => {
        this.$store.dispatch('getImportedFontCSS').then((fontCSS) => {
          this.$store.dispatch('deployBadgeContent',
            {
              ...this.commit,
              mainCss: `${fontCSS}${bodyCSS}`,
              html: cleanHtml,
            }).then(() => {
            this.deploymentSuccessNotif({ message: `Le deploiement en production a bien été éffectué !<br><br><span class="text-muted">${this.commit.id}</span>` });
            this.commit = {
              id: '',
              author: '',
              message: '',
              content: '',
              date: 0,
              checksum: '',
              fontCSS,
              badgeContent: {},
            };
            this.$emit('close');
          });
        });
      });
    },
  },
  notifications: {
    deploymentSuccessNotif: {
      type: VueNotifications.types.success,
      title: 'Déploiement terminé !',
      message: 'Le déploiement en production a bien été effectué !',
    },
  },
};
</script>
<style scoped>
</style>
