<template>
  <modal name="deploymentModal" :width="'80%'" :height="'80%'">
    <card class="h-100">
      <template v-slot:header-left><h4 class="text-white mb-0">{{ dictionary.deploymentModal.title }}</h4></template>
      <template v-slot:header-right><button type="button" class="btn-close btn-close-white" aria-label="Close" @click="close()"></button></template>
      <Tabs class="h-100" :tabs="[dictionary.deploymentModal.deployTabTitle, dictionary.deploymentModal.historyTabTitle]" ref="tabs">
        <template v-slot:slot-0>
          <NewDeployment v-if="shown" ref="newDeployment" v-on:close="close"/>
        </template>
        <template v-slot:slot-1>
          <DeploymentHistory/>
        </template>
      </Tabs>
    </card>
  </modal>
</template>

<script>

import { mapGetters } from 'vuex';
import card from '../../Utilities/Card.vue';
import Tabs from '../../Utilities/Tabs.vue';
import NewDeployment from './NewDeployment.vue';
import DeploymentHistory from './DeploymentHistory.vue';

export default {
  name: 'DeploymentModal',
  components: {
    DeploymentHistory,
    NewDeployment,
    card,
    Tabs,
  },
  data()
  {
    return {
      shown: false,
    };
  },
  computed: {
    ...mapGetters(['dictionary']),
  },
  methods: {
    open()
    {
      this.shown = true;
      this.$modal.show('deploymentModal');
    },
    close()
    {
      this.shown = false;
      this.$modal.hide('deploymentModal');
    },
  },
};

</script>

<style scoped>

</style>
