<template>
  <div class="h-100 d-flex justify-content-center align-items-center align-middle">
    <div class="row w-100 text-center">
      <div class="col-auto mx-auto">
        <img src="https://media2.giphy.com/media/tHy9Qsv4svHXt7Eiq5/giphy.gif?cid=ecf05e47hzlhlny29ly16taz5isdmx8vtn5a4tdck984jhf7&rid=giphy.gif" class="w-50 mb-5" alt="Mobile error page gif">
        <h1 class="title mb-4">{{ dictionary.mobileErrorText }}</h1>
        <button @click="$store.commit('setForceMobileAccess', { dbSync: false, forceMobileAccess: true })" class="btn btn-danger btn-raised text-light">{{ dictionary.continueAnyway }}</button>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'MobileErrorPage',
  computed: {
    ...mapGetters(['dictionary']),
  },
};

</script>

<style scoped>

</style>
