<template>
  <div class="row">
    <div class="col-12 mb-3">
      <button @click="addItems('text')" type="button" class="btn btn-outline-secondary btn-raised w-100 text-start ps-4"><i class="fas fa-font me-3"></i>{{dictionary.text}}</button>
    </div>
    <div class="col-12 mb-3">
      <button @click="addItems('image')" type="button" class="btn btn-outline-secondary btn-raised w-100 text-start ps-4"><i class="fas fa-photo-video me-3"></i>{{dictionary.image}}</button>
    </div>
    <div class="col-12 mb-3">
      <button @click="addItems('qr-code')" type="button" class="btn btn-outline-secondary btn-raised w-100 text-start ps-4"><i class="fas fa-qrcode me-3"></i>{{dictionary.qrCode}}</button>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'NewItems',
  computed: { ...mapGetters(['itemList', 'dictionary']) },
  methods: {
    addItems(itemType)
    {
      const id = this.$chance.string({
        length: 5,
        casing: 'upper',
        alpha: true,
        numeric: false,
      });
      const itemData = { type: itemType, name: `${itemType}-${id}` };
      if (itemType === 'text')
        itemData.text = 'Votre texte ici';
      if (itemType === 'image')
        itemData.backgroundImage = 'https://via.placeholder.com/200x200.png?text=Votre%20image%20ici';
      this.$store.commit('addItem', { itemData, dbSync: true });
      this.$store.commit('setSelectedItem', { selectedItem: this.itemList.length - 1, dbSync: true });
      this.$EventBus.$emit('editItem', this.itemList.length - 1);
    },
  },
};

</script>

<style scoped>

</style>
