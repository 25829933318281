export default {
  backgroundColor: {
    a: 0,
    hex: '#FFFFFF',
    hex8: '#FFFFFF00',
    hsl: {
      a: 0,
      h: 0,
      l: 1,
      s: 0,
    },
    hsv: {
      a: 0,
      h: 0,
      s: 0,
      v: 1,
    },
    oldHue: 0,
    rgba: {
      a: 0,
      b: 255,
      g: 255,
      r: 255,
    },
    source: 'rgba',
  },
  color: {
    a: 1,
    hex: '#000000',
    hex8: '#000000FF',
    hsl: {
      a: 1,
      h: 0,
      l: 1,
      s: 0,
    },
    hsv: {
      a: 1,
      h: 0,
      s: 0,
      v: 1,
    },
    oldHue: 0,
    rgba: {
      a: 1,
      b: 0,
      g: 0,
      r: 0,
    },
    source: 'rgba',
  },
  backgroundImage: '',
  alignment: '',
  spacing: {},
  borderRadius: 0,
  fontFamily: '',
  fontWeight: '',
  fontSize: '',
  backgroundImageProperties: {
    cover: '',
    repetition: '',
    scroll: '',
  },
  border: {
    thickness: 0,
    style: 'solid',
    color: {
      a: 1,
      hex: '#000000',
      hex8: '#000000FF',
      hsl: {
        a: 1,
        h: 0,
        l: 1,
        s: 0,
      },
      hsv: {
        a: 1,
        h: 0,
        s: 0,
        v: 1,
      },
      oldHue: 0,
      rgba: {
        a: 1,
        b: 0,
        g: 0,
        r: 0,
      },
      source: 'rgba',
    },
  },
  hidden: false,
  sticky: false,
  width: 0,
};
