<template>
  <div>
    <div class="mb-3">
      <div class="row mb-2">
        <div class="col pe-0">
          <label for="itemTextArea" class="form-label">Texte de l'élément<span class="ms-2 badge bg-info text-light" v-tooltip="'Ce champs support les moustaches {{\npour les données utilisateur'"><i class="fas fa-info-circle"></i></span></label>
        </div>
        <div class="col-auto ps-0">
          <div class="dropdown">
            <button class="btn btn-success text-light dropdown-toggle" type="button" id="dataSelectorLabel" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="fas fa-user pe-2"></i>Donnée utilisateur
            </button>
            <ul style="height: 250px;" class="dropdown-menu" aria-labelledby="dataSelectorLabel">
              <perfect-scrollbar class="h-100">
                <li v-for="(opt, idx) in eventDataArray" :key="`data-${idx}`"><button @click.stop.prevent="addMustache(opt.variable)" type="button" class="dropdown-item">{{ opt.title }}</button></li>
              </perfect-scrollbar>
            </ul>
          </div>
        </div>
      </div>
      <textarea class="form-control" id="itemTextArea" rows="3" v-model="text"></textarea>
    </div>
    <div class="mb-3">
      <label>Casse du texte</label>
      <select class="form-control" v-model="textTransform">
        <option value="">Par défaut</option>
        <option value="capitalize">Premières Lettres En Majuscule</option>
        <option value="uppercase">MAJUSCULES</option>
        <option value="lowercase">minuscules</option>
      </select>
    </div>
    <div class="mb-3">
      <label>Comportement du texte</label>
      <select class="form-select" aria-label="Default select example" v-model="textBehavior">
        <option value="wrap" selected>Retour a la ligne</option>
        <option value="overflow">Dépassement</option>
        <option value="decrease">Diminuer la taille (avancé)</option>
      </select>
    </div>
    <template v-if="textBehavior === 'decrease'">
      <div class="mb-3 row">
        <div class="col-auto pe-0">
          <label class="small">Caractères minimum</label>
        </div>
        <div class="col input-group">
          <input type="number" step="1" class="form-control" v-model="shrinkCharQuantity">
        </div>
      </div>
      <div class="mb-3 row">
        <div class="col-auto pe-0">
          <label class="small">facteur de diminution</label>
        </div>
        <div class="col input-group">
          <input type="number" step="0.1" class="form-control" v-model="shrinkFactor">
        </div>
      </div>
    </template>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'TextConfig',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['eventData']),
    eventDataArray()
    {
      return this.eventData;
    },
    text: {
      get()
      {
        return this.item.text || '';
      },
      set(newText)
      {
        this.$emit('change', { ...this.item, text: newText });
      },
    },
    textTransform: {
      get()
      {
        return this.item.textTransform || '';
      },
      set(newTransformation)
      {
        this.$emit('change', { ...this.item, textTransform: newTransformation });
      },
    },
    textBehavior: {
      get()
      {
        return this.item.textBehavior || 'wrap';
      },
      set(behavior)
      {
        this.$emit('change', { ...this.item, textBehavior: behavior });
      },
    },
    shrinkFactor: {
      get()
      {
        return this.item.shrinkFactor || 0;
      },
      set(factor)
      {
        this.$emit('change', { ...this.item, shrinkFactor: factor });
      },
    },
    shrinkCharQuantity: {
      get()
      {
        return this.item.shrinkCharQuantity || 0;
      },
      set(quantity)
      {
        this.$emit('change', { ...this.item, shrinkCharQuantity: quantity });
      },
    },
  },
  methods: {
    addMustache(variable)
    {
      this.text = this.text + ' {{' + variable + '}}';
    },
  },
};

</script>

<style scoped>

</style>
