<template>
  <p v-if="items.length <= 0" class="text-center text-muted">Pas d'éléments pour le moment<br>Ajoutez-en avec les boutons ci-dessus</p>
  <draggable v-else v-model="items" :group="{ name: 'formOptions' }" tag="form" handle=".optionDragHandle">
    <div v-for="(item, itemIdx) in items" v-bind:key="`opt-${itemIdx}`" :class="selectedItemID === itemIdx ? 'border-success bg-success text-light' : 'border-primary'" class="row mb-3 px-0 mx-0 d-flex align-items-center border rounded">
      <div class="col-auto">
        <i class="fas fa-grip-lines optionDragHandle cursor-grab"></i>
      </div>
      <div class="col-auto border border-secondary text-secondary rounded-2">
        <i v-if="item.type === 'text'" class="fas fa-font"></i>
        <i v-if="item.type === 'image'" class="fas fa-photo-video"></i>
        <i v-if="item.type === 'qr-code'" class="fas fa-qrcode"></i>
      </div>
      <div class="col">
        <p v-if="!item.name" class="py-2 m-0 fw-bold">{{ item.type }} : {{ itemIdx }}</p>
        <p v-else class="py-2 m-0 fw-bold"> {{ item.name }}</p>
      </div>
      <div class="col-auto pe-1">
        <button class="btn btn-info text-light" @click.stop.prevent="selectItem(item, itemIdx)"><i class="fas fa-pen"></i></button>
      </div>
      <div class="col-auto p-0 pe-1">
        <button class="btn btn-danger text-light" @click.stop.prevent="removeItem(item, itemIdx)"><i class="fas fa-trash"></i></button>
      </div>
    </div>
  </draggable>
</template>

<script>

import { mapGetters } from 'vuex';
import draggable from 'vuedraggable';

export default {
  name: 'ItemList',
  components: {
    draggable,
  },
  methods: {
    removeItem(item, itemID)
    {
      const itemsTmp = this.$store.getters.cloneObject(this.itemList);
      itemsTmp.splice(itemID, 1);
      this.$store.commit('setBadgeItems', { items: itemsTmp });
      this.$emit('forceUpdate');
    },
    selectItem(item, itemIdx)
    {
      this.$store.commit('setSelectedItem', { selectedItem: itemIdx });
      if (itemIdx >= 0)
        this.$EventBus.$emit('editItem', itemIdx);
    },
  },
  computed: {
    ...mapGetters(['itemList', 'selectedItemID']),
    items: {
      get()
      {
        return this.itemList || [];
      },
      set(newItems)
      {
        this.$store.commit('setBadgeItems', { items: newItems });
        this.$emit('forceUpdate');
      },
    },
  },
};

</script>

<style scoped>

</style>
