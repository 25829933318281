<template>
  <div class="row bg-secondary px-3 pt-3 pb-2 bottomWidget">
    <div class="col-auto p-0">
      <div class="input-group">
        <button @click="toggleGrid" :class="snapToGrid ? 'btn-success' : 'btn-outline-success'" v-tooltip="'Activer/Désactiver la grille de guides'" class="btn  text-light"><i class="fa-lg fas fa-border-none"></i></button>
        <input v-model="gridStepValue" v-if="snapToGrid" style="width: 100px;" type="number" class="form-control">
      </div>
    </div>
    <div class="col-auto ps-2 pe-1">
      <button @click="toggleOverlays" :class="showOverlays ? 'btn-success' : 'btn-outline-success'" v-tooltip="'Afficher/Cacher les overlays sur le badge'" class="btn text-light"><i class="fa-lg fas fa-vector-square"></i></button>
    </div>
    <div class="col-auto ps-1 pe-2">
      <button @click="toggleKeepRatio" :class="keepRatio ? 'btn-success' : 'btn-outline-success'" v-tooltip="'Conserver le ratio au redimensionnement (ou maintenir CTRL)'" class="btn text-light"><i class="fa-lg fas fa-crop-alt"></i></button>
    </div>
    <div class="col-auto p-0">
      <a :href="pageUrl" v-tooltip="'Ouvrir l\'éditeur dans un nouvel onglet'" target="_blank" class="btn btn-outline-primary"><i class="fa-lg fas fa-expand"></i></a>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'BottomWidget',
  computed: {
    ...mapGetters(['snapToGrid', 'showOverlays', 'gridStep', 'keepRatio']),
    pageUrl()
    {
      return window.location.href;
    },
    gridStepValue: {
      get()
      {
        return this.gridStep || 0;
      },
      set(newStep)
      {
        this.$store.commit('setGridStep', { value: parseInt(newStep, 10) || 0 });
      },
    },
  },
  methods: {
    toggleGrid()
    {
      this.$store.commit('setSnapToGrid', { value: !this.snapToGrid });
    },
    toggleOverlays()
    {
      this.$store.commit('setShowOverlays', { value: !this.showOverlays });
    },
    toggleKeepRatio()
    {
      this.$store.commit('setKeepRatio', { value: !this.keepRatio });
    },
  },
};

</script>

<style scoped>

.bottomWidget {
  z-index: 900;
  border-top-left-radius: 10px;
}

</style>
