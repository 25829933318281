<template>
  <div v-show="firebaseSpinner" @click="onClick" class="widgetBackground row p-2" >
    <div class="col-auto">
      <PixelSpinner :animation-duration="1500" :size="25" color="#ffffff"/>
    </div>
    <div class="col">
      <p class="m-0 text-white fw-bold small">{{ dictionary.utilities.synchroText }}</p>
    </div>
  </div>
</template>

<script>

import { PixelSpinner } from 'epic-spinners';
import { mapGetters } from 'vuex';

export default {
  name: 'SynchroWidget',
  components: {
    PixelSpinner,
  },
  computed: {
    ...mapGetters(['firebaseSpinner', 'dictionary']),
  },
  methods: {
    onClick()
    {
      this.$store.commit('toggleFirebaseSpinner', { value: false, dbSync: false });
    },
  },
};

</script>

<style scoped>

.widgetBackground {
  border-radius: 30px;
  background-color: var(--bs-primary);
  cursor: pointer;
}

</style>
