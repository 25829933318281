<template>
  <div class="row pt-2 pb-2 pe-2 d-flex align-items-center topbar bg-secondary position-relative">
    <div class="col-auto">
      <button class="btn btn-primary btn-raised" @click="folded = !folded">
        <i v-if="folded" class="fa fa-chevron-left pull-right text-black"></i>
        <i v-else class="fa fa-chevron-right pull-right text-black"></i>
      </button>
    </div>
    <div class="col-auto ms-2">
      <CoworkerIcons/>
    </div>
    <div class="col-auto">
      <button @click="$refs.deploymentModal.open()" class="btn btn-danger btn-raised text-light" type="button"><span v-if="!folded" class="me-3 fw-bold">Sauvegarder</span><i class=" fas fa-save"></i></button>
    </div>
    <div class="col-auto">
      <a :href="testUrl" :disabled="testUrl ? undefined : true" :class="testUrl === '' ? 'disabled' : ''" target="_blank" class="btn btn-light" type="button"><i class="fas fa-external-link-alt fa-lg"></i></a>
    </div>
    <div v-if="(this.coworkers || []).length >= 2" class="position-absolute" style="bottom: -32px; left: 7px;" v-tooltip="'L\'édition a plusieurs est impossible pour les badges. Vous ne verrez pas les modifications des autres utilisateurs'">
      <div class="row w-100">
        <div class="col-auto mx-auto bg-danger" style="border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;">
          <p v-if="folded" class="m-0 my-1 text-light"><i class="fas fa-exclamation-triangle"></i></p>
          <p v-else class="m-0 my-1 text-center text-light fw-bold"><i class="fas fa-exclamation-triangle me-2"></i>Plusieurs utilisateurs</p>
        </div>
      </div>
    </div>
    <DeploymentModal ref="deploymentModal"/>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import CoworkerIcons from './CoworkerIcons.vue';
import DeploymentModal from '../Modals/Deployment/DeploymentModal.vue';
import firebaseReference from '../../store/FirebaseReference';

export default {
  name: 'Topbar',
  components: {
    CoworkerIcons,
    DeploymentModal,
  },
  data()
  {
    return {
      folded: false,
    };
  },
  computed: {
    ...mapGetters(['dictionary', 'coworkers']),
    testUrl()
    {
      return `https://europe-west1-captag-events.cloudfunctions.net/docs/pdf/${firebaseReference.eventID}/${firebaseReference.animID}/__TEST__/test?force=true`;
    },
  },
};

</script>

<style scoped>

.topbar {
  z-index: 900;
  border-bottom-left-radius: 10px;
}

</style>
