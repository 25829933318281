import firebaseReference from '../FirebaseReference';

export default {
  /**
   * Get and return all the documents from firebase
   * these docs can be added through the Event manager software
   * @warning These documents are different from builder's medias
   * @param getters
   * @returns An array containing objects representing documents
   */
  async getDocumentsFromBase({ getters })
  {
    let out = {};
    const fbRefRoot = await firebaseReference.ref;
    await fbRefRoot.ref('docs').child(firebaseReference.eventID).once('value', (snapshot) => {
      out = snapshot.val() || {};
    });
    return out;
  },
  /**
   * Return badge's deployment history
   * @param getters
   * @returns All the past deployment
   */
  async getBadgeLiveContent({ getters })
  {
    let value = {};
    const fbRefRoot = await firebaseReference.root;
    await fbRefRoot.child('live/badgeContent').once('value', (snapshot) => { value = snapshot.val(); });
    return value || undefined;
  },
};
