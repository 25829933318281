<template>
  <form @submit.stop.prevent="" class="col-12">
    <div class="row mb-3">
      <div class="col">
        <button @click="$refs.addFontModal.open()" type="button" class="btn btn-info btn-raised h-100 w-100 text-white">{{ dictionary.leftBar.addOrRemoveFont }}<span class="badge bg-secondary ms-2">{{ googleFontLength }}</span></button>
      </div>
    </div>
    <div class="mb-3">
      <ToolboxWidget class="w-100"/>
    </div>
    <div class="text-center">
      <label class="small text-muted"><a :href="pathLink" class="small text-muted" target="_blank" >{{ path }}</a><br>V. {{ versionNumber }}</label>
    </div>
    <AddFontModal v-model="fontList" ref="addFontModal"/>
  </form>
</template>

<script>

import { mapGetters } from 'vuex';
import AddFontModal from '../../Modals/FontManager/FontManagerModal.vue';
import ToolboxWidget from '../../Utilities/ToolboxWidget.vue';
import firebaseReference from '../../../store/FirebaseReference';

export default {
  name: 'AdvancedConfiguration',
  components: {
    AddFontModal,
    ToolboxWidget,
  },
  computed: {
    ...mapGetters(['googleFonts', 'dictionary', 'versionNumber']),
    fontList: {
      get()
      {
        return this.googleFonts || {};
      },
      set(fontData)
      {
        this.$store.commit('addGoogleFont', { fontObject: fontData, dbSync: true });
      },
    },
    googleFontLength()
    {
      return Object.keys(this.googleFonts || {}).length;
    },
    pathLink()
    {
      return `https://console.firebase.google.com/u/0/project/captag-events/database/captag-events-default-rtdb/data/Storage/WebsiteBuilder/${firebaseReference.eventID}/${firebaseReference.animID}`;
    },
    path()
    {
      return `${firebaseReference.eventID} - ${firebaseReference.animID}`;
    },
  },
};
</script>

<style scoped>

</style>
