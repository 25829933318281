export default {
  /**
   * Return the Google font list form the Google Api
   * @returns an array containing the fonts, empty upon failure
   */
  async getGoogleFontList()
  {
    const response = await fetch('https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyARhQYV7SROMQ2fdaCaPpILJtliMajpEKU');
    const responseBody = await response.json();
    if (!response.ok || !responseBody)
    {
      console.error('Error while loading google font list');
      return [];
    }
    return responseBody.items;
  },

  /**
   * Return the CSS for custom fonts
   * @return string containing the imports
   */
  getImportedFontCSS({ getters })
  {
    let fontsCSS = '';
    Object.values(getters.googleFonts).forEach((element) => {
      if (element.source === 'GoogleFont' && element.GoogleFontURL)
        fontsCSS += `@import url(${element.GoogleFontURL});`;
      if (element.source === 'Custom' && element.fontFace)
        fontsCSS += element.fontFace;
    });
    return fontsCSS;
  },

  /**
   * Return the CSS for website body
   * @return string The actual CSS to apply
   */
  getBodyCSS({ getters })
  {
    let bodyCSS = '';
    if (getters.badgeContent.backgroundImage)
    {
      bodyCSS += 'html {';
      bodyCSS += `    background-image: url(${getters.badgeContent.backgroundImage});`;
      if (getters.badgeContent.backgroundImageProperties)
      {
        const backgroundProperties = getters.badgeContent.backgroundImageProperties;
        if (backgroundProperties.cover)
          bodyCSS += `    background-size: ${backgroundProperties.cover};`;
        if (backgroundProperties.repetition)
          bodyCSS += `    background-repeat: ${backgroundProperties.repetition};`;
        if (backgroundProperties.scroll)
          bodyCSS += `    background-attachment: ${backgroundProperties.scroll};`;
      }
      bodyCSS += '}';
    }
    bodyCSS += 'html, body {';
    if (getters.badgeContent.backgroundColor)
    {
      if (getters.badgeContent.backgroundColor.hex8)
        bodyCSS += `background-color: ${getters.badgeContent.backgroundColor.hex8}!important;`;
      else if (getters.badgeContent.backgroundColor.hex)
        bodyCSS += `background-color: ${getters.badgeContent.backgroundColor.hex}!important;`;
      else if (getters.badgeContent.backgroundColor.rgba)
        bodyCSS += `background-color: rgba(${getters.badgeContent.backgroundColor.rgba.r},
        ${getters.badgeContent.backgroundColor.rgba.g},
        ${getters.badgeContent.backgroundColor.rgba.b},
        ${getters.badgeContent.backgroundColor.rgba.a})!important;`;
    }
    if (getters.badgeContent.fontFamily)
      bodyCSS += `font-family: ${getters.badgeContent.fontFamily}!important;`;
    bodyCSS += '}';
    return bodyCSS;
  },
};
