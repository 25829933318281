<template>
  <div @click="$emit('dismiss')" class="h-100 w-100 position-absolute" style="background-color: rgba(127, 127, 127, 0.5);top: 0; left: 0">
    <div class="row py-4 d-flex align-items-center versionAlert bg-secondary position-absolute" style="left: 50%; top: 50%; transform: translate(-50%, -50%);" @click.stop.prevent>
      <div class="col-12 text-center my-3 pb-3">
        <h3 class="text-light">Une nouvelle version du badge viens d'etre sauvegardé<br>Par <span class="text-primary fw-bold">{{ liveData.author }}</span> le <span class="text-primary fw-bold">{{ formatDate(liveData.date) }}</span></h3>
      </div>
      <div class="col-auto mx-auto ms-md-auto me-md-0 pb-3">
        <button @click="loadNewVersion" class="btn btn-success text-light fw-bold btn-lg">Télécharger la nouvelle version<br><span class="small text-danger">Vous allez perdre votre travail</span></button>
      </div>
      <div class="col-auto mx-auto me-md-auto ms-md-0 pb-3">
        <button @click="$emit('dismiss')" class="btn btn-primary text-light fw-bold btn-lg py-4 px-3">Ignorer</button>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'NewVersionAlert',
  data()
  {
    return {
      history: {},
      liveData: {},
    };
  },
  async mounted()
  {
    await this.$store.dispatch('getBadgeHistory').then((val) => {
      this.loaded = true;
      this.history = val;
    });
    this.liveData = this.liveCommitInfo();
  },
  computed: {
    ...mapGetters(['liveCommit']),
  },
  methods: {
    loadNewVersion()
    {
      this.$store.dispatch('getBadgeLiveContent').then((value) => {
        value.items.forEach((item) => {
          if (!item.position || !item.position.percent)
            return;
          item.position = { percent: { ...item.position.percent } };
        });
        this.$store.commit('setBadgeContent', { content: value });
        this.$emit('reload');
        this.$forceUpdate();
      });
    },
    formatDate(timestamp)
    {
      return this.$formatDate(new Date(timestamp));
    },
    liveCommitInfo()
    {
      let liveCommitData = undefined;
      Object.values(this.history).forEach((commit) => {
        if (commit.id === this.liveCommit.commitId)
          liveCommitData = { ...commit };
      });
      return liveCommitData;
    },
  },
};

</script>

<style scoped>

.versionAlert {
  z-index: 1000;
  border-radius: 5px;
}

</style>
