<template>
  <modal :name="modalID" :width="'75%'" height="80%">
    <card class="h-100">
      <template v-slot:header-left><h6 class="text-white mb-0" v-html="title"></h6></template>
      <template v-slot:header-right><button type="button" class="btn-close btn-close-white" aria-label="Close" @click="close()"></button></template>
      <div class="d-flex flex-column h-100">
        <div class="row d-flex align-items-center flex-shrink-1">
          <div class="col-auto">
            <button class="btn btn-success btn-raised text-white" @click="uploadMedia"><i class="fas fa-upload me-3"></i>{{ dictionary.imageLibraryModal.uploadMediaLabel }}</button>
          </div>
          <div class="col-auto ms-auto">
            <div class="btn-group" role="group">
              <button id="btnGroupDrop1" type="button" class="btn btn-primary dropdown-toggle text-white" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                Filtres <span class="badge bg-secondary">{{ activeFilters }}</span>
              </button>
              <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                <li><a class="dropdown-item text-secondary" @click="filterAll" href="#">{{ dictionary.imageLibraryModal.displayAllLabel }} <i class="fas fa-arrow-right ms-3"></i></a></li>
                <li><hr class="dropdown-divider"></li>
                <li>
                  <a class="dropdown-item d-flex align-items-center text-secondary cursor-pointer" @click="filters.image = !filters.image">
                    <i class="fas fa-image me-2"></i>{{ dictionary.imageLibraryModal.mediaImageType }} <i class="fas fa-check ms-3" v-if="filters.image"></i>
                  </a>
                </li>
                <li>
                  <a class="dropdown-item d-flex align-items-center text-secondary cursor-pointer" @click="filters.video = !filters.video">
                    <i class="fas fa-file-video me-2"></i>{{ dictionary.imageLibraryModal.mediaVideoType }} <i class="fas fa-check ms-3" v-if="filters.video"></i>
                  </a>
                </li>
                <li>
                  <a class="dropdown-item d-flex align-items-center text-secondary cursor-pointer" @click="filters.pdf = !filters.pdf">
                    <i class="fas fa-file-pdf me-2"></i>{{ dictionary.imageLibraryModal.mediaPDFTYpe }} <i class="fas fa-check ms-3" v-if="filters.pdf"></i>
                  </a>
                </li>
                <li>
                  <a class="dropdown-item d-flex align-items-center text-secondary cursor-pointer" @click="filters.ics = !filters.ics">
                    <i class="fas fa-calendar me-2"></i>{{ dictionary.imageLibraryModal.mediaICSType }} <i class="fas fa-check ms-3" v-if="filters.ics"></i>
                  </a>
                </li>
                <li>
                  <a class="dropdown-item d-flex align-items-center text-secondary cursor-pointer" @click="filters.doc = !filters.doc">
                    <i class="fas fa-file me-2"></i>{{ dictionary.imageLibraryModal.mediaDocType }} <i class="fas fa-check ms-3" v-if="filters.doc"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-auto ms-2">
            <div class="form-floating">
              <select class="form-select" id="sortSelect" aria-label="media sorting" v-model="sorting">
                <option value="name">{{ dictionary.imageLibraryModal.mediaNameLabel }}</option>
                <option value="date">{{ dictionary.imageLibraryModal.mediaAddDateLabel }}</option>
              </select>
              <label for="sortSelect">{{ dictionary.imageLibraryModal.sortByLabel }}</label>
            </div>
          </div>
          <div class="col-auto ms-2">
            <div class="form-floating">
              <input v-model="searchQuery" type="text" class="form-control" id="searchInput">
              <label for="searchInput">{{ dictionary.imageLibraryModal.searchMediaLabel }}</label>
            </div>
          </div>
          <div class="col-12">
            <hr class="my-2">
          </div>
        </div>
        <div v-if="loaded" class="flex-grow-1" style="overflow-y: hidden;">
          <perfect-scrollbar class="h-100 pe-3" :options="{ suppressScrollX: true, wheelPropagation: false }">
            <div class="row mb-3">
              <div class="col-auto">
                <h5 class="text-secondary">{{ dictionary.imageLibraryModal.websiteMediaLabel }}</h5>
              </div>
              <div class="col">
                <hr>
              </div>
            </div>
            <div class="row">
              <div class="col-3 mb-3" v-for="(media, mediaIdx) in slicedWebsiteMedias" :key="`doc-${mediaIdx}`">
                <card style="max-height: 300px">
                  <template v-slot:header-center><p class="text-white mb-0 text-center fw-bold">{{ media.title }}</p></template>
                  <div class="row mb-2">
                    <div class="col-12 text-center mb-2">
                      <img class="img-fluid" style="max-height: 100px" :src="media.thumbnail || media.link" alt="media thumbnail">
                    </div>
                    <div class="col-12 text-center mb-2">
                      <p class="m-0 small">{{ dictionary.imageLibraryModal.addAtLabel }} {{ formatDate(media.date) }}</p>
                    </div>
                    <template v-if="!enableSelectButton">
                      <div class="col">
                        <button v-tooltip.top="dictionary.imageLibraryModal.copyLinkTooltip" @click="copyLink(media.link)" class="btn btn-raised btn-success text-white w-100 py-0"><i class="fas fa-copy"></i></button>
                      </div>
                      <div class="col">
                        <a v-tooltip.top="dictionary.imageLibraryModal.openInTabTooltip" :href="media.link" target="_blank" class="btn btn-raised btn-secondary text-white w-100 py-0"><i class="fas fa-external-link-alt"></i></a>
                      </div>
                      <div class="col">
                        <button v-tooltip.top="dictionary.imageLibraryModal.deleteMediaTooltip" :disabled="!media.cloudinary_publicID && !media.deleteURL" :class="!media.cloudinary_publicID && !media.deleteURL ? 'disabled' : ''" @click="deleteMedia(media)" class="btn btn-raised btn-danger text-white w-100 py-0"><i class="fas fa-trash"></i></button>
                      </div>
                    </template>
                    <div v-else class="col-auto mx-auto">
                      <button @click="$emit('import', media); close()" class="btn btn-success text-light"><i class="fas fa-plus me-2"></i>Importer</button>
                    </div>
                  </div>
                </card>
              </div>
            </div>
            <div class="row my-3">
              <div class="col-auto">
                <h5 class="mb-0 text-secondary">{{ dictionary.imageLibraryModal.eventMediaLabel }}</h5>
              </div>
              <div class="col">
                <hr>
              </div>
            </div>
            <div class="row">
              <div class="col-3 mb-3" v-for="(doc, docIdx) in slicedServerDocuments" :key="`doc-${docIdx}`">
                <card style="max-height: 300px">
                  <template v-slot:header-left><i v-tooltip.top="doc.type" :class="getClassForMedia(doc)" class="text-white mb-0"></i></template>
                  <template v-slot:header-center><p class="text-white mb-0 text-center fw-bold">{{ doc.title }}</p></template>
                  <div class="row mb-2">
                    <div class="col-12 text-center mb-3">
                      <img class="img-fluid" style="max-height: 100px" :src="doc.thumb" alt="media thumbnail">
                    </div>
                    <template v-if="!enableSelectButton">
                      <div class="col">
                        <button v-tooltip.top="dictionary.imageLibraryModal.copyLinkTooltip" @click="copyLink(doc.link)" class="btn btn-raised btn-success text-white w-100 py-0"><i class="fas fa-copy"></i></button>
                      </div>
                      <div class="col">
                        <a v-tooltip.top="dictionary.imageLibraryModal.openInTabTooltip" :href="doc.link" target="_blank" class="btn btn-raised btn-secondary text-white w-100 py-0"><i class="fas fa-external-link-alt"></i></a>
                      </div>
                    </template>
                    <div v-else class="col-auto mx-auto">
                      <button @click="$emit('import', doc); close()" class="btn btn-success text-light"><i class="fas fa-plus me-2"></i>Importer</button>
                    </div>
                  </div>
                </card>
              </div>
            </div>
            <div v-if="searchQuery !== '' && slicedServerDocuments.length <= 0" class="row">
              <div class="col-12 text-center">
                <h6 class="text-muted">{{ dictionary.imageLibraryModal.noElementFound }}</h6>
              </div>
            </div>
          </perfect-scrollbar>
        </div>
        <div v-else class="row">
          <div class="col-auto mx-auto mt-4">
            <h3 class="text-muted">{{ dictionary.loadingMessage }}</h3>
          </div>
        </div>
        <input class="form-control" :value="mediaLink" id="hiddenLinkInput" ref="hiddenLinkInput" style="height: 0; font-size: 0; border: unset; cursor: default">
      </div>
    </card>
  </modal>
</template>

<script>

import { mapGetters } from 'vuex';
import VueNotifications from 'vue-notifications';
import Card from '../Utilities/Card.vue';
import firebaseReference from '../../store/FirebaseReference';

export default {
  name: 'ImageLibraryModal',
  components: {
    Card,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    enableSelectButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data()
  {
    return {
      websiteMedias: {},
      serverDocuments: {},
      loaded: false,
      searchQuery: '',
      sorting: 'date',
      cmpt_modalID: '',
      filters: {
        image: true,
        video: true,
        pdf: true,
        ics: true,
        doc: true,
      },
      mediaLink: '',
    };
  },
  computed: {
    ...mapGetters(['dictionary']),
    modalID()
    {
      if (!this.cmpt_modalID)
      {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.cmpt_modalID = `imageLibraryModal${this.$chance.string()}`;
      }
      return this.cmpt_modalID;
    },
    slicedServerDocuments()
    {
      return Object.values(this.serverDocuments).filter((doc) => {
        let typeOK;
        switch (doc.type)
        {
          case 'JPG': typeOK = this.filters.image; break;
          case 'PNG': typeOK = this.filters.image; break;
          case 'XLSX': typeOK = this.filters.doc; break;
          case 'XLS': typeOK = this.filters.doc; break;
          case 'PDF': typeOK = this.filters.pdf; break;
          case 'MP4': typeOK = this.filters.video; break;
          case 'PPTX': typeOK = this.filters.doc; break;
          case 'LIVE': typeOK = this.filters.video; break;
          case 'WEBINAR': typeOK = this.filters.video; break;
          case 'REPLAY': typeOK = this.filters.video; break;
          case 'LINK': typeOK = this.filters.doc; break;
          case 'ICS': typeOK = this.filters.ics; break;
          default: typeOK = false;
        }
        if (typeOK && this.searchQuery)
          return doc.title.includes(this.searchQuery);
        return typeOK;
      });
    },
    slicedWebsiteMedias()
    {
      return Object.values(this.websiteMedias || {}).filter((doc) => {
        if (this.searchQuery)
          return doc.title.includes(this.searchQuery);
        return true;
      }).sort((a, b) => {
        if (this.sorting === 'name' || this.sorting === '')
        {
          if (a.title < b.title)
            return -1;
          if (a.title > b.title)
            return 1;
          return 0;
        }
        if (this.sorting === 'date')
        {
          if (a.date < b.date)
            return 1;
          if (a.date > b.date)
            return -1;
          return 0;
        }
        return 0;
      });
    },
    activeFilters()
    {
      let out = 0;
      Object.values(this.filters || {}).forEach((boolean) => { out += (boolean) ? 1 : 0; });
      return out;
    },
  },
  methods: {
    formatDate(timestamp)
    {
      return this.$formatDate(new Date(timestamp));
    },
    loadDatas()
    {
      this.loaded = false;
      this.$store.dispatch('getDocumentsFromBase').then((docs) => {
        this.serverDocuments = docs;
        this.$store.dispatch('getWebsiteMedias').then((medias) => {
          this.websiteMedias = medias;
          this.loaded = true;
        });
      });
    },
    uploadMedia()
    {
      window.cptgUploader.init('identifiantUnique', `${firebaseReference.eventID}/badge/img/`, {
        apiKey: 'iFxsKguXQdlc7SbA6Fn3',
        sources: ['local'],
        lang: this.language || 'fr',
        theme: 'light',
        useEditor: false,
        forceSquareCrop: false,
        maxFileSize: 20,
      },
      (result) => {
        this.$store.dispatch('addToMediaList', {
          deleteURL: result.deleteURL || '',
          fileID: result.fileID || '',
          link: result.originalURL || '',
          thumbnail: result.thumbURL || '',
          title: `media-${this.$chance.string(this.$newIDParams)}`,
        }).then(() => this.uploadSuccess())
          .then(() => this.loadDatas());
      }).open();
    },
    copyLink(mediaLink)
    {
      const copyText = document.getElementById('hiddenLinkInput');
      this.mediaLink = mediaLink;
      copyText.value = mediaLink;
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand('copy');
      this.copyLinkNotif();
    },
    async deleteMedia(media)
    {
      await this.$store.dispatch('removeFromMediaList', { id: media.id });
      if (media.deleteURL)
        await this.$store.dispatch('removeFile', { deleteURL: media.deleteURL });
      this.removeSuccess();
      this.loadDatas();
    },
    getClassForMedia(media)
    {
      switch (media.type)
      {
        case 'JPG': return 'fas fa-image';
        case 'PNG': return 'fas fa-image';
        case 'XLSX': return 'fas fa-file-excel';
        case 'XLS': return 'fas fa-file-excel';
        case 'PDF': return 'fas fa-file-pdf';
        case 'MP4': return 'fas fa-file-video';
        case 'PPTX': return 'fas fa-file-powerpoint';
        case 'LIVE': return 'fas fa-file-video';
        case 'WEBINAR': return 'fas fa-file-video';
        case 'REPLAY': return 'fas fa-file-video';
        case 'LINK': return 'fas fa-link';
        case 'ICS': return 'fas fa-calendar';
        default: return '';
      }
    },
    filterAll()
    {
      Object.keys(this.filters).forEach((key) => {
        this.filters[key] = true;
      });
    },
    open()
    {
      this.$modal.show(this.modalID);
      this.loadDatas();
    },
    close()
    {
      this.$modal.hide(this.modalID);
    },
  },
  notifications: {
    copyLinkNotif: {
      type: VueNotifications.types.success,
      title: 'Lien du fichier copié !',
      message: 'Le lien du fichier a été copié dans votre presse papier !',
    },
    uploadSuccess: {
      type: VueNotifications.types.success,
      title: 'Image uploadé !',
      message: 'Image uploadé avec succès.',
    },
    removeSuccess: {
      type: VueNotifications.types.success,
      title: 'Image supprimée !',
      message: 'Image supprimée avec succès.',
    },
    uploadError: {
      type: VueNotifications.types.error,
      title: 'Erreur',
      message: 'Une erreur est survenue lors de l\'envois de l\'image.',
    },
  },
};

</script>

<style scoped>

</style>
