<template>
  <div>
    <div class="mb-3">
      <label>Contenus du Qr code</label>
      <select :value="getValueFromItem('contentType', 'userCode')"
              @change="setValueForItem('contentType', $event.target.value)"
              class="form-select">
        <option value="userCode" selected>QR Code de l'utilisateur</option>
        <option value="userID">ID de l'utilisateur</option>
        <option value="brutValue">Texte</option>
      </select>
    </div>
    <div class="mb-3" v-if="getValueFromItem('contentType', '') === 'brutValue'">
      <label for="itemTextArea" class="form-label">Texte de l'élément<span class="ms-2 badge bg-info text-light" v-tooltip="'Ce champs support les moustaches {{\npour les données utilisateur'"><i class="fas fa-info-circle"></i></span></label>
      <textarea :value="getValueFromItem('contentText', '')"
                @change="setValueForItem('contentText', $event.target.value)"
                class="form-control" id="itemTextArea" rows="3"/>
    </div>
    <div class="mb-3">
      <input-color-picker :value="getValueFromItem('color', {})" @change="setValueForItem('color', $event)" label="Couleur du Qr code"/>
    </div>
    <div class="mb-3">
      <label>Complexité du QR code</label>
      <select :value="getValueFromItem('complexity', 'L')"
              @change="setValueForItem('complexity', $event.target.value)"
              class="form-select">
        <option value="L" selected>Basse</option>
        <option value="M">Moyenne</option>
        <option value="Q">Complexe</option>
        <option value="H">Maximum</option>
      </select>
    </div>
    <div class="mb-3">
      <label>Marges<span class="ms-2 badge bg-info text-light" v-tooltip="'Pour être correctement lu un QR doit être entouré de marges blanches'"><i class="fas fa-info-circle"></i></span></label>
      <input :value="getValueFromItem('margin', '4')"
             @change="setValueForItem('margin', $event.target.value)"
             type="number" class="form-control">
    </div>
  </div>
</template>

<script>

import InputColorPicker from '@/components/Utilities/InputColorPicker.vue';

export default {
  name: 'QrConfig',
  components: { InputColorPicker },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getValueFromItem(field, fallback)
    {
      return this.item[field] || fallback;
    },
    setValueForItem(field, newValue)
    {
      const newItem = { ...this.item };
      newItem[field] = newValue;
      this.$emit('change', newItem);
    },
  },
};

</script>

<style scoped>

</style>
