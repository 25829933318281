<template>
  <div class="h-100 row">
    <div class="col-12 h-100 text-center" v-if="mode === 'start'">
      <div class="row h-25 justify-content-center d-flex align-items-center">
        <div class="col-12 text-center text-secondary">
          <h1><i class="text-primary fas fa-hammer me-3"></i>{{ dictionary.pageTitle }}</h1>
        </div>
      </div>
      <div class="row justify-content-center d-flex align-items-center" style="height: 30%">
        <div :class="(selected === 'A4') ? 'formTypeSelected' : ''"
            @click="formCardClick('A4')"
            class="col-auto me-1 h-100 py-5 px-1 formTypeCard formTypeCardFull d-flex align-items-center justify-content-center">
          <div class="row text-center my-3">
            <div class="col-12 mb-4">
              <img style="width: 80px" src="assets/a4.png" alt="a4">
            </div>
            <div class="col-12">
              <h4 class="fw-bold">Badge A4</h4>
              <p class="m-0">Format : 210 <span class="small"></span> <span class="small">x</span> 297 <span class="small">mm</span></p>
              <p>Ratio : 1.41</p>
            </div>
          </div>
        </div>
        <div :class="(selected === 'CR80') ? 'formTypeSelected' : ''"
             @click="formCardClick('CR80')"
             class="col-auto h-100 mx-1 py-5 px-1 formTypeCard formTypeCardFull d-flex align-items-center justify-content-center">
          <div class="row text-center">
            <div class="col-12 mb-4">
              <img style="width: 80px" src="assets/credit-card.png" alt="credit card">
            </div>
            <div class="col-12">
              <h4 class="fw-bold">Badge "Carte bleu"</h4>
              <p class="m-0">Format : 85,7 <span class="small"></span> <span class="small">x</span> 54,03 <span class="small">mm</span></p>
              <p>Ratio : 1.58</p>
            </div>
          </div>
        </div>
        <div :class="(selected === 'papillon') ? 'formTypeSelected' : ''"
             @click="formCardClick('papillon')"
             class="col-auto h-100 mx-1 py-5 px-1 formTypeCard formTypeCardFull d-flex align-items-center justify-content-center">
          <div class="row text-center">
            <div class="col-12 mb-4">
              <h1 class="formPicto fas fa-cut"></h1>
            </div>
            <div class="col-12">
              <h4 class="fw-bold">Badge "Papillon"</h4>
              <p class="m-0">Format : 107 <span class="small"></span> <span class="small">x</span> 260 <span class="small">mm</span></p>
              <p>Ratio : 2.42</p>
            </div>
          </div>
        </div>
        <div :class="(selected === 'PapillonsV2') ? 'formTypeSelected' : ''"
             @click="formCardClick('PapillonsV2')"
             class="col-auto h-100 mx-1 py-5 px-1 formTypeCard formTypeCardFull d-flex align-items-center justify-content-center">
          <div class="row text-center">
            <div class="col-12 mb-4">
              <h1 class="formPicto fas fa-bug"></h1>
            </div>
            <div class="col-12">
              <h4 class="fw-bold">Badge 8.6/13 "Coccinelle"</h4>
              <p class="m-0">Format : 85 <span class="small"></span> <span class="small">x</span> 260 <span class="small">mm</span></p>
              <p>Ratio : 3.05</p>
            </div>
          </div>
        </div>
        <div :class="(selected === 'etiquette') ? 'formTypeSelected' : ''"
             @click="formCardClick('etiquette')"
             class="col-auto h-100 py-5 mx-1 px-1 formTypeCard formTypeCardFull d-flex align-items-center justify-content-center">
          <div class="row text-center">
            <div class="col-12 mb-4">
              <h1 class="formPicto fas fa-sticky-note"></h1>
            </div>
            <div class="col-12">
              <h4 class="fw-bold">Etiquette</h4>
              <p class="m-0">Format : 70 <span class="small"></span> <span class="small">x</span> 30 <span class="small">mm</span></p>
              <p>Ratio : 2.32</p>
            </div>
          </div>
        </div>
        <div
            :class="(selected === 'US Letter') ? 'formTypeSelected' : ''"
            @click="formCardClick('US Letter')"
            class="col-auto h-100 py-5 mx-1 px-1 formTypeCard formTypeCardFull d-flex align-items-center justify-content-center">
          <div class="row text-center">
            <div class="col-12 mb-4">
              <h1 class="formPicto fas fa-envelope-open"></h1>
            </div>
            <div class="col-12">
              <h4 class="fw-bold">US Letter</h4>
              <p class="m-0">Format : 215.9 <span class="small"></span> <span class="small">x</span> 279.4 <span class="small">mm</span></p>
              <p>Ratio : 2.32</p>
            </div>
          </div>
        </div>
        <div :class="(selected === 'import') ? 'formTypeSelected' : ''"
             @click="formCardClick('import')"
             class="col-auto h-100 ms-1 py-5 px-1 formTypeCard formTypeCardOutline d-flex align-items-center justify-content-center">
          <div class="row text-center">
            <div class="col-12">
              <h1 class="formPicto fas fa-file-import"></h1>
            </div>
            <div class="col-12">
              <h4 class="fw-bold">Importer un badge</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="row h-25 justify-content-center d-flex align-items-center">
        <div class="col-12 text-center" v-if="mode === 'start'">
          <button :disabled="selected === ''" :class="selected === '' ? 'btn-outline-dark' : ' text-light btn-success'" @click="validateClick" class="btn btn-lg">{{ dictionary.continue.toUpperCase() }}</button>
        </div>
        <div class="col-12">
          <input @change="readImportData" accept="application/JSON" ref="importHiddenInput" type="file" style="visibility: hidden">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import VueNotifications from 'vue-notifications';
import { mapGetters } from 'vuex';
import BadgeDefaultContent from '../configuration/BadgeDefaultContent.json';

export default {
  name: 'NewWebsiteWizzard',
  data()
  {
    return {
      selected: '',
      mode: 'start',
    };
  },
  computed: {
    ...mapGetters(['dictionary']),
  },
  methods: {
    importWebsite(content)
    {
      this.$store.commit('setBadgeContent', { content });
    },
    formCardClick(selected)
    {
      this.selected = selected;
    },
    validateClick()
    {
      if (this.selected === 'import')
        this.$refs.importHiddenInput.click();
      else
      {
        this.$store.commit('setBadgeContent', {
          content: {
            ...BadgeDefaultContent[this.selected],
            origin: this.selected,
          },
          dbSync: true,
        });
      }
    },
    readImportData(ev)
    {
      const file = ev.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        try {
          const object = JSON.parse(e.target.result);
          if (object)
            this.importWebsite(object);
        } catch (ex)
        {
          console.log(`Invalid badge content file : ${ex.message}`);
        }
      };
      reader.readAsText(file);
    },
  },
  notifications: {
    websiteImported: {
      type: VueNotifications.types.success,
      title: 'Badge importé !',
      message: 'Le badge a été importé avec succès',
    },
    blankSiteCreated: {
      type: VueNotifications.types.success,
      title: 'Badge crée !',
      message: 'Amusez vous bien :)',
    },
  },
};

</script>

<style scoped>

.formTypeCard {
  border-radius: 15px;
  color: white;
  cursor: pointer;
}

.formTypeCardFull {
  background-color: var(--bs-secondary);
  border: 2px solid var(--bs-secondary);
}

.formTypeCardOutline {
  border: 2px solid var(--bs-secondary);
  color: var(--bs-secondary)
}

.formTypeSelected {
  border: 2px solid var(--bs-primary);
  color: var(--bs-primary);
}

.formTypeCard:not([disabled]):hover {
  border: 2px solid var(--bs-primary);
  color: var(--bs-primary);
}

</style>
