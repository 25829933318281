<template>
  <contextMenu  :id="`${this.contextId}-workerinfo-context-menu`" :ref="`${this.contextId}-workerinfo-ctxMenu`" style="width: 200px;">
    <div class="row my-3">
      <div class="col-12 text-center mb-3">
        <button :style="{ backgroundColor: worker.color.primary }" class="btn btn-lg btn-primary rounded-circle btn-raised text-center" style="vertical-align: center; border: unset;" type="button" >
          <h3 class="mt-2">{{ worker.initial.toUpperCase() }}</h3>
        </button>
      </div>
      <div v-if="isYou" class="col-12 text-center text-danger">
        <h5>{{ dictionary.you }}</h5>
      </div>
      <div class="col-12 text-center">
        <h5>{{ worker.name }}</h5>
      </div>
      <div v-if="worker.selectedColumnID" class="col-12 text-center">
        <p><span class="text-secondary">{{ dictionary.isEditingTheColumn }} :</span><br> {{ worker.selectedColumnID }}</p>
      </div>
      <div v-if="!isYou" class="col-12 text-center">
        <p><span class="text-secondary">{{ dictionary.seen }} :</span> {{ formatDate(worker.lastUpdate) }}</p>
      </div>
    </div>
  </contextMenu>
</template>

<script>

import contextMenu from 'vue-context-menu';
import { mapGetters } from 'vuex';

export default {
  name: 'WorkerInfoContext',
  components: {
    contextMenu,
  },
  props: {
    id: {
      required: false,
      type: String,
      default: 'unset',
    },
    isYou: {
      required: false,
      type: Boolean,
      default: false,
    },
    worker: {
      required: true,
      type: Object,
    },
  },
  data()
  {
    return {
      contextId: '',
    };
  },
  created()
  {
    this.contextId = (this.id === 'unset') ? this.contextId = this.getNewId() : this.id;
  },
  computed: {
    ...mapGetters(['dictionary', 'language']),
  },
  methods: {
    formatDate(timestamp)
    {
      return this.$distanceDate(new Date(timestamp));
    },
    openContext()
    {
      this.$refs[`${this.contextId}-workerinfo-ctxMenu`].open();
    },
    getNewId()
    {
      return this.$chance.string({
        length: 5,
        casing: 'upper',
        alpha: true,
        numeric: true,
      });
    },
  },
};
</script>

<style scoped>

</style>
